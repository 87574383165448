import { Box, Flex } from '@chakra-ui/react'
import { Gap } from '../../../mini-lib/gap/Gap'
import { COLORS } from '../../../mini-lib/theme/colors'
import React from 'react'
import logoAndName from '../../../assets/signup/salonscale-icon-name.svg'
import { times } from 'lodash'

export const SignupFormLayout = (props: { children: any; stepNumber?: number; totalSteps?: number }) => {
  const { children, stepNumber, totalSteps } = props
  return (
    <Box minH="100vh" h="100%" bg={COLORS.shades_neutral_50}>
      <Gap s="32px" />
      <Flex justify="center">
        <Header />
      </Flex>
      <Gap s="32px" />
      <Flex align="center" justify="center">
        <Box
          m="12px"
          maxW="550px"
          width="100%"
          minH="350px"
          border={`1px solid ${COLORS.shades_neutral_300}`}
          bg={COLORS.shades_neutral_0}
          borderRadius="10px"
        >
          {stepNumber && totalSteps && (
            <>
              <Gap />
              <ColoredCircles currentStep={stepNumber} totalSteps={totalSteps} />
            </>
          )}
          {children}
        </Box>
      </Flex>
    </Box>
  )
}

const ColoredCircles = (props: { totalSteps: number; currentStep: number; }) => {
  const { totalSteps, currentStep } = props
  return (
    <Flex justify="center" gridGap="4px">
      {times(totalSteps, (index) => {
        return <ColoredCircle key={index} stepIndex={index} currentStep={currentStep} />
      })}
    </Flex>
  )
}

const ColoredCircle = (props: { currentStep: number; stepIndex: number }) => {
  const { currentStep, stepIndex } = props
  return (
    <Box
      h="8px"
      w="8px"
      borderRadius="50px"
      bg={currentStep === stepIndex + 1 ? COLORS.lavender_500 : COLORS.shades_neutral_300}
    ></Box>
  )
}
const Header = () => {
  return (
    <Flex align="center">
      <img alt="logo" style={{ height: '40px' }} src={logoAndName} />
    </Flex>
  )
}
// const Logo = () => {
//   return (
//     <Flex position="absolute" top="-30px" w="100%" justify="center">
//       <Box
//         w="90px"
//         h="90px"
//         bg={COLORS.lavender_100}
//         borderRadius="100px"
//         p="6px"
//         border={`5px solid ${COLORS.shades_neutral_0}`}
//       >
//         <img alt="logo" style={{ height: '100%', width: '100%', padding: '4px' }} src={logo} />
//       </Box>
//     </Flex>
//   )
// }
