
export const ROUTES = {
  // unauthed
  login: `/login`,
  logout: `/logout`,
  blackFriday: `/black-friday`,
  scheduledMaintenance: `/scheduledMaintenance`,
  userDashboard: `/user-dashboard`,
  cancellationPolicy: `/cancellation-policy`,
  refundPolicy: `/refund-policy`,
  minilibForms: `/minilib/forms`,
  minilibKitchenSink: `/minilib/sink`,

  // signup v3 start
  signupV2Type: '/signup/v2/type',
  signupV2User: '/signup/v2/user',
  signupV2Salon: '/signup/v2/salon',
  signupV2PartsAndLabor: '/signup/v2/parts-and-labor',
  signupV2Problems: '/signup/v2/problems',
  signupV2Description: '/signup/v2/description',
  signupV2Colors: '/signup/v2/colors',
  signupV2SuccessTrial: '/signup-success/trial',

  // authed and salon context
  base: `/`,

  baseSalon: `/salon/:salonId`,
  setInitialPassword: `/salon/:salonId/set-password`,
  home: `/salon/:salonId/home`,
  vendors: `/salon/:salonId/vendors`,
  supplies: `/salon/:salonId/addons`,
  supplyOnboard: `/salon/:salonId/addon-onboard`,

  labor: `/salon/:salonId/labor`,
  legacyLabor: `/salon/:salonId/labor-legacy`,
  laborOnboard: `/salon/:salonId/labor-onboard`,
  laborSoloOnboard: `/salon/:salonId/labor-solo-onboard`,
  laborTeamOnboard: `/salon/:salonId/labor-team-onboard`,

  extensions: `/salon/:salonId/extensions`,
  extensionsAdd: `/salon/:salonId/extensions-add`,
  lines: `/salon/:salonId/lines`,
  inventorySnapshot: `/salon/:salonId/inventory-snapshot`,
  inventoryAudits: `/salon/:salonId/inventory-audits`,
  inventoryAddStock: `/salon/:salonId/inventory-add-stock`,
  inventoryStockAlerts: `/salon/:salonId/inventory-stock-alerts`,
  inventoryCount: `/salon/:salonId/count/:countId`,
  addLinesV2: `/salon/:salonId/add-lines-v2`,
  addExtensionsV2: `/salon/:salonId/add-extensions-v2`,
  sessionDetailsLegacy: `/salon/:salonId/session-details-legacy/:sessionId`,
  sessionDetailsV2: `/salon/:salonId/session-details-v2/:sessionId`,
  sessionDetails: `/salon/:salonId/session-details/:sessionId`,
  sessions: `/salon/:salonId/sessions`,
  sessionsByMember: `/salon/:salonId/sessions-by-member`,
  stylists: `/salon/:salonId/stylists`,
  startGuide: `/salon/:salonId/start-guide`,
  startGuideV2: `/salon/:salonId/start-guide-v2`,

  //// reports start
  //
  //
  // reports v1
  colorReportsStylist: `/salon/:salonId/reports/color-reports/stylist`,
  colorReportsClient: `/salon/:salonId/reports/color-reports/client`,
  extensionReports: `/salon/:salonId/reports/extension-reports`,
  extensionStylistReports: `/salon/:salonId/reports/extension-stylist-reports`,
  extensionClientReports: `/salon/:salonId/reports/extension-client-reports`,
  addonReports: `/salon/:salonId/reports/addon-reports`,
  addonStylistReports: `/salon/:salonId/reports/addon-stylist-reports`,
  addonClientReports: `/salon/:salonId/reports/addon-client-reports`,

  // reports v2
  colorBrandReports: `/salon/:salonId/reports/color-brand-reports`,
  allColorBrandUnusedProductReports: `/salon/:salonId/reports/all-color-brand-unused-products-report`,
  colorBrandUsedProductReports: `/salon/:salonId/reports/color-brand-used-products-report/:brand`,
  colorBrandUnusedProductReports: `/salon/:salonId/reports/color-brand-unused-products-report/:brand`,
  colorLineWasteProductsReports: `/salon/:salonId/reports/color-line-waste-products-report/:brand`,
  allColorLineWasteProductsReports: `/salon/:salonId/reports/all-color-line-waste-products-report`,

  colorStylistReports: `/salon/:salonId/reports/color-stylist-reports`,
  colorStylistWasteReports: `/salon/:salonId/reports/color-stylist-waste-reports`,
  colorStylistProductsReports: `/salon/:salonId/reports/color-stylist-products-reports/:stylistId`,
  colorStylistWasteProductsReports: `/salon/:salonId/reports/color-stylist-waste-products-reports/:stylistId`,

  colorClientReports: `/salon/:salonId/reports/color-client-reports`,

  laborLaborReports: `/salon/:salonId/reports/labor-reports`,
  laborStylistReports: `/salon/:salonId/reports/labor-stylist-reports`,
  laborClientReports: `/salon/:salonId/reports/labor-client-reports`,
  //
  //
  //// reports end

  orders: `/salon/:salonId/orders`,
  ordersCreate: `/salon/:salonId/orders-create`,
  orderDetails: `/salon/:salonId/order-details/:orderId`,
  clients: `/salon/:salonId/clients`,
  clientsBulkUpload: `/salon/:salonId/clients-bulk`,
  tutorials: `/salon/:salonId/tutorials`,
  userSettings: `/salon/:salonId/user-settings`,
  salonSettings: `/salon/:salonId/salon-settings`,
  permissionSettings: `/salon/:salonId/permission-settings`,
  settings: `/salon/:salonId/settings`,
  integrationSettings: `/salon/:salonId/integrations`,

  squareConnectAuthorization: `/salon/:salonId/square/authorize`,
  squareConnectTeam: `/salon/:salonId/square/connect-team`,
  squareOAuthCallback: `/square-oauth-callback`,

  signupPlans: `/signup`,
  signupAccessories: `/signup-accessories`,
  signupCheckout: `/signup-checkout`,
  signupSubscriptionRedirect: `/signup-success`,
  forgotPassword: `/forgot-password`,
  resetPassword: `/reset-password`,

  // misc
  stylistInviteSignup: `/join`, // this has to match the angular equivalent because texts navigate here
  existingStylistSalonJoinRedirect: `/salon-invite`,
  salonSignup: `/signup/salon`,
  userSignup: `/signup/user`,

  // trial v1
  baseTrial: `/salon/:salonId/trial`,

  trialIndex: `/salon/:salonId/trial/index`,
  trialSalonSettings: `/salon/:salonId/trial/salon-settings`,
  userSettingsNoNav: `/salon/:salonId/user/settings`,

  baseCheckout: `/salon/:salonId/checkout`,
  checkoutSubscription: `/salon/:salonId/checkout/subscription`,
  checkoutFreeScale: `/salon/:salonId/checkout/scale`,
  checkoutPaidScale: `/salon/:salonId/checkout/paid-scale`,
  checkoutCart: `/salon/:salonId/checkout/cart`,

  // start guide v2
  baseStartGuide: `/salon/:salonId/guide`,
  sgIntro: `/salon/:salonId/guide/intro`,
  sgAddLinesIntro: `/salon/:salonId/guide/lines-intro`,
  sgAddLines: `/salon/:salonId/guide/lines-add`,
  sgAddPricingIntro: `/salon/:salonId/guide/pricing-intro`,
  sgAddPricing: `/salon/:salonId/guide/pricing-add`,
  sgAutoPricing: `/salon/:salonId/guide/pricing-auto`,
  sgIndex: `/salon/:salonId/guide/index`,
  sgAddTeam: `/salon/:salonId/guide/team`,
  sgAddTeamIntro: `/salon/:salonId/guide/team-intro`,
  sgAddClients: `/salon/:salonId/guide/clients`,
  sgAddClientsIntro: `/salon/:salonId/guide/clients-intro`,
  sgAddons: `/salon/:salonId/guide/addons`,
  sgAddonsOnboarding: `/salon/:salonId/guide/addons-onboarding`,
  sgAddAddonsIntro: `/salon/:salonId/guide/addons-intro`,
  sgExtensions: `/salon/:salonId/guide/extensions`,
  sgAddExtensions: `/salon/:salonId/guide/extensions-add`,
  sgAddExtensionsV2: `/salon/:salonId/guide/extensions-add-v2`,
  sgAddExtensionsIntro: `/salon/:salonId/guide/extensions-intro`,
  sgPOSIntro: `/salon/:salonId/guide/pos-intro`,

  sgInventoryIntro: `/salon/:salonId/guide/inventory-intro`,
  sgInventoryTargets: `/salon/:salonId/guide/inventory-targets`,
  sgInventoryTargetsIntro: `/salon/:salonId/guide/inventory-targets-intro`,
  sgInventoryOnHand: `/salon/:salonId/guide/inventory-on-hand`,
  sgInventoryOnHandIntro: `/salon/:salonId/guide/inventory-on-hand-intro`,
  sgInventoryStockAlertsIntro: `/salon/:salonId/guide/inventory-stock-alerts-intro`,
  sgInventoryOrdersIntro: `/salon/:salonId/guide/inventory-orders-intro`,
  sgInventoryAddingStockIntro: `/salon/:salonId/guide/inventory-adding-stock-intro`,

  sgLaborIntro: `/salon/:salonId/guide/labor-intro`,

  sgSessionsIntro: `/salon/:salonId/guide/sessions-intro`,
  sgAcademyIntro: `/salon/:salonId/guide/academy-intro`,

  // Notifications
  notifications: `/salon/:salonId/notifications/:notificationId`,

  // admin
  adminUpcUpload: `/admin/upcs`,
  adminAnalyticsDashboard: `/admin/analytics/dashboard`,
  adminUsersAnalytics: `/admin/analytics/users`,
  adminWebinarDataReset: `/admin/reset-webinar-data`,
  adminDataReset: `/admin/reset-data`,
  adminMasterProducts: `/admin/master-products`,
  adminBulkUploadMasterProducts: `/admin/master-colors/bulk-upload`,
  adminGrinIntegration: `/admin/grin-integration`,

  // super-admin
  adminNotification: `/admin/create/notification`,
  adminValidateUpc: `/admin/validate/upc`,
}
