/////////////// slice.ts
//
//

// references
//
// slice redux docs - https://redux-toolkit.js.org/tutorials/typescript
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { assign, get, keyBy, omit } from 'lodash'

import { RootState } from '../../store'
import { SquareMember } from "./interfaces";

// state
//
interface SupplyState {
  squareMembersById: { [key: string]: SquareMember } | null
  squareConnectionResponse: string | null // note: this is only used for the initial connection attempt
  bookingPermissions: string[]
}

const initialState: SupplyState = {
  // squareMembersById: keyBy(MOCK_SQUARE_MEMBERS, 'squareId'),
  squareMembersById: null,
  squareConnectionResponse: null,
  bookingPermissions: []
}

// reducer
//
export const IntegrationsSlice = createSlice({
  name: 'supplies',
  initialState,
  reducers: {
    reduceListSquareMembers: (state, action: PayloadAction<SquareMember[]>) => {
      state.squareMembersById = assign({}, state.squareMembersById, keyBy(action.payload, 'squareId'))
    },
    reduceRemoveSquareMember: (state, action: PayloadAction<string>) => {
      state.squareMembersById = omit(state.squareMembersById, action.payload)
    },
    reduceUpdateSquareMember: (state, action: PayloadAction<{id: string, properties: Partial<SquareMember>}>) => {
      const existing = get(state.squareMembersById, action.payload.id, {})
      const updated = assign({}, existing, action.payload.properties)
      state.squareMembersById = assign({}, state.squareMembersById, {[action.payload.id]: updated })
    },
    reduceSquareConnectionResponse: (state, action: PayloadAction<string>) => {
      state.squareConnectionResponse = action.payload
    },
    reduceBookingPermissions: (state, action: PayloadAction<any>) => {
      state.bookingPermissions = action.payload
    }
  },
})

// actions
//
export const {
  reduceListSquareMembers,
  reduceRemoveSquareMember,
  reduceUpdateSquareMember,
  reduceSquareConnectionResponse,
  reduceBookingPermissions
} = IntegrationsSlice.actions

// selectors
//
export const selectSquareMembersById = (state: RootState) => {
  return state.integrations.squareMembersById
}

export const selectSquareConnectionResponse = (state: RootState): string | null => {
  return state.integrations.squareConnectionResponse
}

export const selectBookingPermissions = (state: RootState): string[] => {
  return state.integrations.bookingPermissions
}

// export
//
export default IntegrationsSlice.reducer
