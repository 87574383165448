import React, {useEffect, useState} from 'react'
import {Box, Button, Flex, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tooltip, Tr} from '@chakra-ui/react'
import { CsvUpload } from '../../mini-lib/csv/CsvUpload'
import { useAppSelector } from '../../hooks'
import { useDispatch } from 'react-redux'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'

import { COLORS } from '../../mini-lib/theme/colors'
import { EmptyBox } from '../../mini-lib/empty/EmptyBox'
import { selectLoadingState } from '../../core/loading/slice'
import { Loading } from '../../mini-lib/loading/Loading'
import { PageHeader } from '../../mini-lib/page-header/PageHeader'
import {
  dispatchAdminBulkUpsertMasterColorsLara
} from '../../data/admin-master-products/api'
import {ADMIN_CREATE_MASTER_PRODUCTS} from '../../data/admin-master-products/constants'
import {APIBulkUpsertMasterColorLara, CSVColor} from '../../data/admin-master-products/interfaces'
import {
  mapUploadErrors,
  mergeCreatedColorsWithCsvRows,
  mergeMatchesAndRows
} from "../../data/admin-master-products/utils";
import {
  reduceUploadErrors,
  selectBulkMatches,
  selectCreatedMasterColors,
  selectUploadErrors
} from "../../data/admin-master-products/slice";
import {MaterialIcon} from "../../mini-lib/icons/MaterialIcon";
import {mapKeys} from "lodash";

export const AdminBulkUploadMasterProductsPage = () => {
  const dispatch = useDispatch()
  const [rows, setRows] = useState<any>([])
  const [uploadAttempted, setUploadAttempted] = useState(false)
  const { user } = UseBaseApiParams()
  const bulkMatches = useAppSelector(selectBulkMatches)
  const uploadErrors = useAppSelector(selectUploadErrors)
  const createdMasterColors = useAppSelector(selectCreatedMasterColors)

  // const loadingBulkMatches = useAppSelector((state) => selectLoadingState(state, ADMIN_LOAD_BULK_MATCHES))
  const loadingCreateColors = useAppSelector((state) => selectLoadingState(state, ADMIN_CREATE_MASTER_PRODUCTS))

  // only update the rows when bulk matches updates
  useEffect(() => {
    const rowsWithMatches = mergeMatchesAndRows(rows, bulkMatches)
    setRows(rowsWithMatches)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulkMatches?.vendors?.length]);

  // const onCheckColors = () => {
  //   const vendors: string[] = []
  //   const lines: string[] = []
  //   const categories: string[] = []
  //   const types: string[] = []
  //   rows.forEach((row: CSVColor) => {
  //     row.vendor && vendors.push(row.vendor)
  //     row.line && lines.push(row.line)
  //     row.category && categories.push(row.category)
  //     row.type && types.push(row.type)
  //   })
  //   const body: APIGetBulkMatches = {
  //     vendor_names: vendors,
  //     line_names: lines,
  //     category_names: categories,
  //     type_names: types,
  //   }
  //   dispatch(
  //     dispatchAdminBulkMatchMasterColorsLara({
  //       token: user.token,
  //       body,
  //       loadingName: ADMIN_LOAD_BULK_MATCHES,
  //     }),
  //   )
  // }

  const onFileUpload = (fileRows: any) => {
    dispatch(reduceUploadErrors(null))
    setUploadAttempted(false)
    const normalizedRows = normalizeCsvUploadRows(fileRows)
    setRows(normalizedRows)
  }

  const onUpsertMasterColors = () => {
    const models: APIBulkUpsertMasterColorLara[] = rows.map((row: CSVColor) => {
      return {
        vendor_name: row.vendor,
        line_name: row.line,
        category_name: row.category,
        type_name: row.type,
        size: row.size,
        kind: row.kind,
        level: row.level || null,
        volume: row.volume || null,
        units: row.units || null,
        upc: row.upc,
      }
    })
    dispatch(
      dispatchAdminBulkUpsertMasterColorsLara({
        token: user.token,
        models,
        loadingName: ADMIN_CREATE_MASTER_PRODUCTS,
      }),
    )
    setUploadAttempted(true)
  }
  const filteredRows = rows

  const filteredRowsMergedWithCreatedColors = createdMasterColors ? mergeCreatedColorsWithCsvRows({rows: filteredRows, masterProducts: createdMasterColors}) : filteredRows

  const errorMap = mapUploadErrors(uploadErrors)
  return (
    <Box maxW="1200px" margin="auto">
      <PageHeader title="Upload Master Products" breadcrumbs={[]} />
      <Box h="24px" />
        {uploadErrors && <Box p='24px' bg={COLORS.peach_50}> All Errors <Text>{JSON.stringify(uploadErrors)}</Text></Box>}
      <Box h="24px" />
      <Flex gridGap="12px" justify="end" align="center">
        <Flex gridGap="12px">
          <CsvUpload
            buttonLabel={rows.length > 0 ? "Re-upload csv" : 'upload csv'}
            theme="midnight"
            variant={rows.length > 0 ? 'round-outline' : 'round'}
            onFileUpload={(fileRows) => onFileUpload(fileRows)}
          />
          {rows?.length > 0 && (
            <>
              <Button variant={rows.length > 0 ? 'round' : 'round-outline'} colorScheme="brand.midnight" onClick={onUpsertMasterColors} w='130px'>
                 {loadingCreateColors
                     ? <Loading spinnerhex={COLORS.lavender_500} />
                     : "create MPS"
                 }
              </Button>
              {/*<Button variant="round-outline" colorScheme="brand.midnight" onClick={onCheckColors}>*/}
              {/*   {loadingBulkMatches*/}
              {/*       ? <Loading spinnerhex={COLORS.lavender_500} />*/}
              {/*       : "check colors"*/}
              {/*   }*/}
              {/*</Button>*/}
            </>
          )}
        </Flex>
      </Flex>

      <Box h="40px" />
      <Text variant="title3">CSV content</Text>
      <Box h="12px" />
        {filteredRowsMergedWithCreatedColors.length > 0 && (
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th><Text fontSize='10px'>Index</Text></Th>
                  {uploadAttempted && <Th><Text fontSize='10px'>Status</Text></Th>}
                  {uploadAttempted && <Th><Text fontSize='10px'>Id</Text></Th>}
                  <Th><Text fontSize='10px'>Parent</Text></Th>
                  <Th><Text fontSize='10px'>Brand</Text></Th>
                  <Th><Text fontSize='10px'>Category</Text></Th>
                  <Th><Text fontSize='10px'>Type</Text></Th>
                  <Th><Text fontSize='10px'>Size</Text></Th>
                  <Th><Text fontSize='10px'>Kind</Text></Th>
                  <Th><Text fontSize='10px'>Unit</Text></Th>
                  <Th><Text fontSize='10px'>Level</Text></Th>
                  <Th><Text fontSize='10px'>Volume</Text></Th>
                  {uploadAttempted && errorMap && <Th><Text fontSize='10px'>Errors</Text></Th>}
                </Tr>
              </Thead>
              <Tbody>
                {filteredRowsMergedWithCreatedColors?.map((row, index) => {
                  return <Row key={index} uploadAttempted={uploadAttempted} row={row} rowErrors={errorMap && errorMap[index] ? errorMap[index] : []} />
                })}
              </Tbody>
            </Table>
          </TableContainer>
        )}

      {rows.length === 0 && <EmptyBox h="500px" content='please upload some data' />}
    </Box>
  )
}

const Row = (props: { row: CSVColor, rowErrors: string[], uploadAttempted: boolean }) => {
  const { row, rowErrors, uploadAttempted } = props
  return (
    <Tr>
      <Td>
        <Text>{row.index}</Text>
      </Td>
      {uploadAttempted && (
        <Td>
        <Text>
          {uploadAttempted && rowErrors && rowErrors.length > 0 && <MaterialIcon colorhex={COLORS.danger} name='error'/>}
          {uploadAttempted && !!row.id && <MaterialIcon name="check_circle" colorhex={COLORS.success_500} />}
          {uploadAttempted && !row.id && rowErrors.length === 0 && (
            <Tooltip label='this row has no issues but was not created due to errors in other rows'>
              <Box>
                <MaterialIcon name="warning" colorhex={COLORS.lavender_500} />
              </Box>
            </Tooltip>
          )}
        </Text>
      </Td>
      )}
      {uploadAttempted && (
        <Td>
          <Text>{row.id ? row.id : ''}</Text>
        </Td>
      )}
      <Td>
        <Tooltip label={row.vendor && row.vendor.length > 10? row.vendor : ''}>
          <Text maxW='100px' w='100px' isTruncated>{row.vendor ? row.vendor : <MaterialIcon colorhex={COLORS.danger} name='error'/>}</Text>
        </Tooltip>
      </Td>
      <Td>
        <Tooltip label={row.line && row.line.length > 10? row.line : ''}>
          <Text maxW='100px' w='100px' isTruncated>{row.line ? row.line : <MaterialIcon colorhex={COLORS.danger} name='error'/>}</Text>
        </Tooltip>
      </Td>
      <Td>
        <Tooltip label={row.category && row.category.length > 10? row.category : ''}>
          <Text maxW='100px' w='100px' isTruncated>{row.category ? row.category : <MaterialIcon colorhex={COLORS.danger} name='error'/>}</Text>
        </Tooltip>
      </Td>
      <Td>
        <Tooltip label={row.type && row.type.length > 10? row.type : ''}>
          <Text maxW='100px' w='100px' isTruncated>{row.type ? row.type : <MaterialIcon colorhex={COLORS.danger} name='error'/>}</Text>
        </Tooltip>
      </Td>
      <Td>
        <Text> {row.size ? row.size : <MaterialIcon colorhex={COLORS.danger} name='error'/>}</Text>
      </Td>
      <Td>
        <Text> {row.kind ? row.kind : <MaterialIcon colorhex={COLORS.danger} name='error'/>}</Text>
      </Td>
      <Td>
        <Text> {row.units ? row.units : row.kind === 'color' ? <MaterialIcon colorhex={COLORS.danger} name='error'/> : <></>}</Text>
      </Td>
      <Td>
        <Text>{row.level ? row.level : row.kind === 'color' ? <MaterialIcon colorhex={COLORS.danger} name='error'/> : <></>}</Text>
      </Td>
      <Td>
        <Text>{row.volume ? row.volume : row.kind === 'color' ? <MaterialIcon colorhex={COLORS.danger} name='error'/> : <></>}</Text>
      </Td>
      {rowErrors && (
        <Td maxW='200px' overflowX='scroll'>
          {rowErrors.join(', ')}
        </Td>
      )}
    </Tr>
  )
  // return (
  //   <Box m="12px 0" p="0 12px" bg={COLORS.shades_neutral_50} color="black" borderRadius="15px">
  //     <Flex gridGap="12px" align="center" p="24px 0">
  //       {row.index && <Text w="200px">{row.index}</Text>}
  //       {row.vendor && <Text w="200px">{row.vendor}</Text>}
  //       {row.line && <Text w="180px">{row.line}</Text>}
  //       {row.category && <Text w="100px">{row.category}</Text>}
  //       {row.size && <Text w="50px">{row.size}</Text>}
  //       {row.type && <Text w="240px">{row.type}</Text>}
  //       {row.level && <Text w="50px">{row.level}</Text>}
  //       {row.volume && <Text w="50px">{row.volume}</Text>}
  //       <Text w="180px">{row.upc}</Text>
  //       <Text w="100px">
  //         {row.matchesMasterColor === null ?  ''
  //           : row.matchesMasterColor
  //               ? 'update'
  //               : 'create'
  //         }
  //       </Text>
  //     </Flex>
  //   </Box>
  // )
}

const normalizeCsvUploadRows = (rows: any): CSVColor[] => {
  return rows.map((row, index: number) => {

    const normalizedRow = mapKeys(row, (_, key) => key.toLowerCase())

      return {
        id: null,
        index,
        line: normalizedRow['brand'] || '',
        vendor: normalizedRow['parent_brand'] || normalizedRow['parent brand'] || '',
        type: normalizedRow['type'] || '',
        category: normalizedRow['category'] || '',
        size: normalizedRow['size'] || '',
        upc: normalizedRow['upc'] || '',
        level: normalizedRow['level'] || '',
        volume: normalizedRow['volume'] || '',
        units: normalizedRow['units'] || '',
        kind: normalizedRow['kind'] || 'color',
        matchesMasterColor: null,
      }
  })
}
