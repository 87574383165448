// external interfaces
//


export interface APIMasterProduct {
  id: number
  parent_brand_name: string
  parent_brand_logo_url: string | null
  brand_name: string
  type: string
  category: string
  size: number
  units: string | null
  upcs: string[]
}

export interface APIMasterProductUpcCreate {
  master_product_id: number
  upc: number
}

export interface APIMasterProductTrialPrice {
  master_product_id: number
  avg_salon_purchase_price: number
}

// internal interfaces
//
export interface MasterProduct {
  id: number
  vendorId?: number
  parentBrandName: string
  parentBrandLogoUrl: string | null
  lineId?: number
  brandName: string
  type: string
  categoryId?: number
  category: string
  size: number
  level?: string | null
  volume?: string | null
  units: string | null
  upcs: string[]
}

export interface CSVMasterProduct {
  id: number
  brand: string
  parent_brand: string
  type: string
  category: string
  size: number
  upc: number | string

  level?: string
  volume?: string
  units?: string

  existing: MasterProduct[]
  matches: MasterProduct[]
}

export interface MasterProductTrialPrice {
  masterProductId: number
  trialPrice: number
}

export type CsvRowStatus = 'upc exists' | 'matches' | 'no matches' | string
export type CsvRowType = 'csv' | 'product' | string

export const csvStatusOptions = [
  { label: 'all', value: '' },
  { label: 'has upc already', value: 'upc exists' },
  { label: 'update', value: 'matches' },
  { label: 'create', value: 'no matches' },
]
export const rowTypeOptions = [
  { label: 'all', value: '' },
  { label: 'csv', value: 'csv' },
  { label: 'product', value: 'product' },
]

export interface APIMasterProductLara {
  id: number
  brand_id: number
  line_id: number
  category_id: number
  vendor_id: number

  name: string
  line_name: string
  vendor_name: string
  vendor_logo_url: string

  category: string
  level: string
  volume: string

  size: number
  units: string
  upcs: string[]
}
