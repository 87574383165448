import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
} from '@chakra-ui/react'
import React, {useEffect, useState} from 'react'
import { useAppSelector } from '../../../hooks'
import { useDispatch } from 'react-redux'
import { UseViewSize } from '../../../core/UseViewSize'
import { APICreateOrUpdateSessionLaborItemLara, SessionLaborItemLara } from '../../../data/sessions/interfaces'
import {
  reduceIsLaborSheetVisible,
  selectActiveSessionLabors,
  selectIsLaborSheetVisible
} from '../../../data/sessions/slice'
import { MaterialIcon } from '../../../mini-lib/icons/MaterialIcon'
import { LaborSelectSheet } from './LaborSelectSheet'
import { DisableAutofocus } from '../../../mini-lib/disable-autofocus/DisableAutofocus'
import { UseBaseApiParams } from "../../../core/UseBaseApiParams";
import { useParams } from "react-router-dom";
import { EmptyBox } from "../../../mini-lib/empty/EmptyBox";
import { find } from "lodash";
import { Loading } from "../../../mini-lib/loading/Loading";
import {
  dispatchCreateOrUpdateSessionLaborItemsLara,
  dispatchDeleteSessionLaborItemLara,
} from '../../../data/sessions/session-labor/api'
import {LaborItem} from "../../../data/labor-legacy/interfaces";
import {TimeInput} from "../../../mini-lib";
import {convertHoursMinutesToSeconds, convertSecondsToHoursMinutes} from "../../../mini-lib/form/TimeInput";

export const SessionLaborSheet = () => {
  const params: any = useParams()
  const { sessionId } = params
  const dispatch = useDispatch()
  const { isMobile } = UseViewSize()

  const isLaborSheetVisible = useAppSelector(selectIsLaborSheetVisible)
  const sessionLaborItems = useAppSelector(selectActiveSessionLabors)
  const {user, salonId} = UseBaseApiParams()

  const [showLaborSheet, setShowLaborSheet] = useState(false)

  const updateLaborItem = (sessionLaborItem: SessionLaborItemLara, durationSecondsOrQuantity: number) => {
    const updatedSessionLabor: APICreateOrUpdateSessionLaborItemLara = {
      id: sessionLaborItem.id, labor_item_id: sessionLaborItem.laborItemId, duration_seconds: durationSecondsOrQuantity }
    dispatch(dispatchCreateOrUpdateSessionLaborItemsLara({ token: user.token, salonId, sessionId, models: [updatedSessionLabor] }))
  }

  const addSelectedLaborItems = (itemsToAdd: LaborItem[]) => {
    const laborItemsToCreateOrUpdate: APICreateOrUpdateSessionLaborItemLara[] = []
    itemsToAdd.forEach((laborItem) => {
      // don't re add any labors that already exist
      const alreadyExists: SessionLaborItemLara | undefined = find(
        sessionLaborItems,
        (item) => item?.laborItemId === laborItem.id,
      )
      if (!alreadyExists) {
        const newSessionLabor: APICreateOrUpdateSessionLaborItemLara = {
          labor_item_id: laborItem.id,
          duration_seconds: 0
        }
        laborItemsToCreateOrUpdate.push(newSessionLabor)
      }
    })
    const laborsToDelete: SessionLaborItemLara[] = []
    sessionLaborItems?.forEach(sessionLaborItems => {
      const LaborItemsToAdd = find(itemsToAdd, (labor) => labor.id === sessionLaborItems.laborItemId)
      if (!LaborItemsToAdd) {
        laborsToDelete.push(sessionLaborItems)
      }
    })
    laborsToDelete.forEach(sessionLaborItems => {
      dispatch(dispatchDeleteSessionLaborItemLara({token: user.token, salonId, sessionId, sessionLaborItemId: sessionLaborItems.id}))
    })

    dispatch(dispatchCreateOrUpdateSessionLaborItemsLara({ token: user.token, salonId, sessionId, models: laborItemsToCreateOrUpdate }))
  }

  const onClose = () => {
    dispatch(reduceIsLaborSheetVisible(false))
  }
  const selectedLaborItemIds: number[] = sessionLaborItems ? sessionLaborItems.map((item) => item.laborItemId) : []
  return (
    <Drawer
      size={isMobile ? 'xs' : 'md'}
      isOpen={isLaborSheetVisible}
      placement="right"
      onClose={() => dispatch(reduceIsLaborSheetVisible(false))}
    >
      {showLaborSheet && (
        <LaborSelectSheet
          selectedLaborItemIds={selectedLaborItemIds}
          show={showLaborSheet}
          onHide={() => setShowLaborSheet(false)}
          onLaborItemsSelected={(labors) => {
            addSelectedLaborItems(labors)
            setShowLaborSheet(false)
          }}
        />
      )}
      <DrawerOverlay />
      <DrawerCloseButton />
      <DrawerContent>
        <DrawerHeader>
          <Flex justify="space-between" align="center">
            <Text variant="title3">Select Services</Text>
            <Flex align="center" gridGap="12px">
              <Button colorScheme="brand.skylight" variant="round" onClick={onClose} minW="100px">
                Done
              </Button>
            </Flex>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          <>
            <DisableAutofocus />
            <Box h="24px" />
            <Flex justify='end'>
              <Text color="text.secondary">Time (hours)/Quantity</Text>
            </Flex>
            <Box h="24px" />

            {sessionLaborItems &&
              sessionLaborItems.length > 0 &&
              sessionLaborItems.map((sessionLaborItem, index) => {
                return (
                  <LaborRow key={index} sessionLaborItem={sessionLaborItem} updateLaborItem={updateLaborItem} />
                )
              })}
            {sessionLaborItems && sessionLaborItems.length === 0 && <EmptyBox h="200px" />}
            {!sessionLaborItems && <Loading/>}
            <Box h="12px" />
            <Flex justify="flex-end">
              <Button variant="round-outline" colorScheme="brand.skylight" onClick={() => setShowLaborSheet(true)}>
                <MaterialIcon name="add" style={{ marginRight: '4px' }} /> Services
              </Button>
            </Flex>
          </>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export const LaborRow = (props: {
  sessionLaborItem: SessionLaborItemLara,
  updateLaborItem: (sessionLaborItem: SessionLaborItemLara, durationSecondsOrQuantity: number) => void
}) => {
  const dispatch = useDispatch()
  const { sessionLaborItem, updateLaborItem } = props
  const params: any = useParams()
  const { sessionId } = params
  const {user, salonId} = UseBaseApiParams()

  const onDelete = () => {
    dispatch(dispatchDeleteSessionLaborItemLara({token: user.token, salonId, sessionId, sessionLaborItemId: sessionLaborItem.id}))
  }
  useEffect(() => {
    const hoursAndMinutes = convertSecondsToHoursMinutes(sessionLaborItem.durationSeconds)
    setTimeHours(hoursAndMinutes.hours ?? null)
    setTimeMinutes(hoursAndMinutes.minutes ?? null)
  }, [dispatch, sessionLaborItem.durationSeconds])

  const [timeHours, setTimeHours] = useState<number | null>(null)
  const [timeMinutes, setTimeMinutes] = useState<number | null>(null)
  const [timeFieldErrorText, setTimeFieldErrorText] = useState('')
  const validateTimeField = (timeFieldVal: { hours: number | null, minutes: number | null }) => {
    if (!timeFieldVal.hours && !timeFieldVal.minutes) {
      setTimeFieldErrorText('please enter at least 1 minute ')
    } else {
      setTimeFieldErrorText('')
    }
  }
  return (
    <Flex key={sessionLaborItem.id} align="center" justify="space-between" p="0 0 24px 0" cursor="pointer">
      <Box>
        <Text variant="title3">{sessionLaborItem?.laborItem.serviceName}</Text>
        <Text color="text.secondary">{sessionLaborItem?.type}</Text>
      </Box>
      <Flex align="center" gridGap="12px">
        {sessionLaborItem.type === 'service'
          ? (
          <Box>
            <NumberInput
              maxW='130px'
              w='130px'
              defaultValue={sessionLaborItem.durationSeconds || 0}
              min={0}
              onBlur={(evt) => updateLaborItem(sessionLaborItem,  parseFloat(evt.target.value))}
              borderRadius="100px"
            >
              <NumberInputField borderRadius="100px" />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </Box>
            ) : (
           <TimeInput
            label="Service Time"
            value={{ hours: timeHours, minutes: timeMinutes }}
            errorText={timeFieldErrorText}
            onBlur={(val) => validateTimeField(val)}
            onChange={(val) => {
              if (val) {
                setTimeHours(val.hours);
                setTimeMinutes(val.minutes)
                const seconds = convertHoursMinutesToSeconds(val)
                updateLaborItem(sessionLaborItem, seconds)
              }
            }}
          />

          )}
        <MaterialIcon mt="16px" name="delete" onClick={onDelete} />
      </Flex>
    </Flex>
  )
}
