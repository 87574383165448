import React, { useState } from 'react'
import { Box, Button, Flex, Text } from '@chakra-ui/react'
import * as Yup from 'yup'
import { Formik } from "formik";
import { Gap } from "../gap/Gap";
import { TextInput } from "./TextInput";
import { buildFormikProps } from "./utils";
import { SelectInput } from "./SelectInput";
import { PhoneInput } from "./PhoneInput";
import { PasswordInput } from "./PasswordInput";
import {RadioGroupInput, TimeInput} from "../index";
import {RadioOption} from "./RadioGroupInput";
import {CheckboxGroupInput, CheckboxOption} from "./CheckboxGroupInput";


export const MinilibFormCodeExamplesPage = () => {
  return (
    <Flex justify='center'>
      <Box w='450px'>
        <Text variant='title1'>Formik Example</Text>
        <Gap/>
        <ExampleForm/>

        <Gap s='48px'/>
        <Text variant='title1'>Manual Example</Text>
        <Gap/>
        <ExampleNonForm/>
      </Box>
    </Flex>
  )
}

interface FormProps {
  textProp: string
  selectProp: string
  phoneProp: string
  passwordProp: string
  radioProp: RadioOption | null
  checkboxProp: CheckboxOption[]
}

export const ExampleForm = () => {

  const initialValues: FormProps = {
    textProp: '',
    selectProp: '',
    phoneProp: '',
    passwordProp: '',
    radioProp: null,
    checkboxProp: [],
  }
  const validationSchema = Yup.object({
    textProp: Yup.string().required('Text is required').min(2, 'You need at least 2 characters'),
    selectProp: Yup.string().required('Select is required'),
    passwordProp: Yup.string().required('Password is required').min(8, 'You need at least 8 characters'),
    phoneProp: Yup.string().required('Phone is required'),
    radioProp: Yup.object().required('Radio is required'),
    checkboxProp: Yup.array().of(Yup.object().required()).min(1, 'Checkbox is required').required('Checkbox is required'),
  })
  const submitClicked = ( values: FormProps ) => {
    console.info({ values })
  }
  return (
    <Box w='100%'>
      <Formik onSubmit={submitClicked} initialValues={initialValues} validationSchema={validationSchema}>
        {( {
             handleSubmit,
             resetForm,
             // you could manually unpack the variables here if you wanted
             //
             //
             // validateForm,
             // values,
             // touched,
             // setFieldTouched,
             // setTouched,
             // errors,
             // setFieldValue,
             // setFieldError,
             //
             //
             ...formikParams
           } ) => {
          const textFieldParams = buildFormikProps({ name: 'textProp', formikParams })
          const selectFieldParams = buildFormikProps({ name: 'selectProp', formikParams })
          const phoneFieldParams = buildFormikProps({ name: 'phoneProp', formikParams })
          const passwordFieldParams = buildFormikProps({ name: 'passwordProp', formikParams })
          const radioFieldParams = buildFormikProps({ name: 'radioProp', formikParams })
          const checkboxFieldParams = buildFormikProps({ name: 'checkboxProp', formikParams })
          return (


            <>

              <Gap/>
               {/* material form field */}
              <TextInput
                variant='material'
                label="First Name"
                value={textFieldParams.value}
                errorText={textFieldParams.errorText}
                onBlur={textFieldParams.onBlur}
                onChange={textFieldParams.onChange}
              />
              <Gap/>

              {/*text examples*/}
              {/**/}
              {/**/}
              {/**/}
              {/* inside a formik form you can build the utils to just spread the props */}
              <TextInput
                placeholder='enter text'
                label="Text Example"
                {...textFieldParams}
              />
              {/* or you can manually unpack them from your helper */}
              <TextInput
                placeholder='enter text'
                label="Text Example"
                value={textFieldParams.value}
                errorText={textFieldParams.errorText}
                onBlur={textFieldParams.onBlur}
                onChange={textFieldParams.onChange}
              />


              {/* or you can manually interface with the input one by one without the helper */}
              <TextInput
                placeholder='enter text'
                label="Text Example"
                value={formikParams.values['textProp']}
                errorText={formikParams.touched['textProp'] ? formikParams.errors['textProp'] : ''}
                onBlur={val => formikParams.setFieldTouched('textProp', true)}
                onChange={val => {
                  formikParams.setFieldValue('textProp', val);
                }}
              />

              <Gap/>

              {/*select examples*/}
              {/**/}
              {/**/}
              {/**/}
              <SelectInput
                variant='material'
                placeholder='select an option'
                label="Material Select Example"
                options={[{ label: 'option 1', value: '1' }, { label: 'option 2', value: '2' }]}
                value={selectFieldParams.value}
                errorText={selectFieldParams.errorText}
                onBlur={(v) => {selectFieldParams.onBlur(v)}}
                onChange={(v) => {selectFieldParams.onChange(v)}}
              />
              <Gap/>

              <SelectInput
                placeholder='select an option'
                label="Select Example"
                options={[{ label: 'option 1', value: '1' }, { label: 'option 2', value: '2' }]}
                {...selectFieldParams}
              />
              <Gap/>
              <SelectInput
                placeholder='select an option'
                label="Select Example"
                options={[{ label: 'option 1', value: '1' }, { label: 'option 2', value: '2' }]}
                value={selectFieldParams.value}
                errorText={selectFieldParams.errorText}
                onBlur={selectFieldParams.onBlur}
                onChange={selectFieldParams.onChange}
              />
              <SelectInput
                placeholder='select an option'
                label="Select Example"
                options={[{ label: 'option 1', value: '1' }, { label: 'option 2', value: '2' }]}
                value={formikParams.values['selectProp']}
                errorText={formikParams.touched['selectProp'] ? formikParams.errors['selectProp'] : ''}
                onBlur={val => formikParams.setFieldTouched('selectProp', true)}
                onChange={val => {
                  formikParams.setFieldValue('selectProp', val);
                }}
              />

              <Gap/>

              {/*phone examples*/}
              {/**/}
              {/**/}
              {/**/}
              <PhoneInput
                variant='material'
                label='Material Phone Example'
                value={phoneFieldParams.value}
                errorText={phoneFieldParams.errorText}
                onBlur={phoneFieldParams.onBlur}
                onChange={phoneFieldParams.onChange}
                onError={phoneFieldParams.onError}
              />
              <PhoneInput
                label='Phone Example'
                {...phoneFieldParams}
              />
              <PhoneInput
                label='Phone Example'
                value={phoneFieldParams.value}
                errorText={phoneFieldParams.errorText}
                onBlur={phoneFieldParams.onBlur}
                onChange={phoneFieldParams.onChange}
                onError={phoneFieldParams.onError}
              />
              <PhoneInput
                label='Phone Example'
                value={formikParams.values['phoneProp']}
                onBlur={val => formikParams.setFieldTouched('phoneProp', true)}
                onChange={val => formikParams.setFieldValue('phoneProp', val)}
                errorText={formikParams.touched['phoneProp'] ? formikParams.errors['phoneProp'] : ''}
                onError={err => formikParams.setFieldError('phoneProp', err)}
              />

              <Gap/>

              <PasswordInput
                placeholder='enter password'
                label="Password Example"
                {...passwordFieldParams}
              />

              <Gap/>
              <PasswordInput
                variant='material'
                label="Material Password Example"
                value={passwordFieldParams.value}
                onBlur={passwordFieldParams.onBlur}
                onChange={passwordFieldParams.onChange}
                errorText={passwordFieldParams.errorText}
              />
              <Gap/>
              <PasswordInput
                placeholder='enter password'
                label="Password Example"
                value={passwordFieldParams.value}
                onBlur={passwordFieldParams.onBlur}
                onChange={passwordFieldParams.onChange}
                errorText={passwordFieldParams.errorText}
              />
              <PasswordInput
                placeholder='enter password'
                label="Password Example"
                value={formikParams.values['passwordProp']}
                onBlur={() => formikParams.setFieldTouched('passwordProp', true)}
                onChange={val => formikParams.setFieldValue('passwordProp', val)}
                errorText={formikParams.touched['passwordProp'] ? formikParams.errors['passwordProp'] : ''}
              />

              <Gap/>
              <Text>Radio Input Example</Text>
              <Gap/>
              <RadioGroupInput
                value={radioFieldParams.value}
                errorText={radioFieldParams.errorText}
                onBlur={radioFieldParams.onBlur}
                onChange={radioFieldParams.onChange}
                options={[{label: 'label', value: 'value'}, {label: 'label2', value: 'value2'}]}
              />

              <Gap/>
              <Text>Checkbox Input Example</Text>
              <Gap/>
              <CheckboxGroupInput
                selectedOptions={checkboxFieldParams.value}
                errorText={checkboxFieldParams.errorText}
                onBlur={checkboxFieldParams.onBlur}
                onChange={checkboxFieldParams.onChange}
                options={[{label: 'label', value: 'value'}, {label: 'label2', value: 'value2'}]}
              />

              <Gap s='48px'/>

              <Button onClick={() => handleSubmit()}>
                Submit
              </Button>

            </>

          )
        }}
      </Formik>

    </Box>
  )
}


export const ExampleNonForm = () => {

  const [textField, setTextField] = useState('')
  const [textFieldErrorText, setTextFieldErrorText] = useState('')
  const validateTextField = ( textFieldVal: string ) => {
    if (textFieldVal.length < 2) {
      setTextFieldErrorText('please enter at least two characters')
    } else {
      setTextFieldErrorText('')
    }
  }

  const [timeHours, setTimeHours] = useState<number | null>(null)
  const [timeMinutes, setTimeMinutes] = useState<number | null>(null)
  const [timeFieldErrorText, setTimeFieldErrorText] = useState('')
  const validateTimeField = (timeFieldVal: { hours: number | null, minutes: number | null }) => {
    if (!timeFieldVal.hours && !timeFieldVal.minutes) {
      setTimeFieldErrorText('please enter at least 1 minute ')
    } else {
      setTimeFieldErrorText('')
    }
  }


  const [selectField, setSelectField] = useState('')
  const [selectFieldErrorText, setSelectFieldErrorText] = useState('')
  const validateSelectField = ( selectFieldVal: string ) => {
    if (!selectFieldVal) {
      setSelectFieldErrorText('please select a value')
    } else {
      setSelectFieldErrorText('')
    }
  }

  const [phoneField, setPhoneField] = useState('')
  const [phoneFieldErrorText, setPhoneFieldErrorText] = useState('')

  const [passwordField, setPasswordField] = useState('')
  const [passwordFieldErrorText, setPasswordFieldErrorText] = useState('')
  const validatePasswordField = ( passwordFieldVal: string ) => {
    if (passwordFieldVal.length < 8) {
      setPasswordFieldErrorText('password must be at least 8 characters')
    } else {
      setPasswordFieldErrorText('')
    }
  }

  const [radioField, setRadioField] = useState<RadioOption | null>(null)
  const [radioFieldErrorText, setRadioFieldErrorText] = useState('')
  const validateRadioField = ( radioFieldVal: RadioOption | null ) => {
    if (!radioFieldVal) {
      setRadioFieldErrorText('please select a value')
    } else {
      setRadioFieldErrorText('')
    }
  }

  const [checkboxField, setCheckField] = useState<CheckboxOption[]>([])
  const [checkboxFieldErrorText, setCheckFieldErrorText] = useState('')
  const validateCheckField = ( checkboxFieldVal: CheckboxOption[] ) => {
    if (checkboxFieldVal.length === 0) {
      setCheckFieldErrorText('please select a value')
    } else {
      setCheckFieldErrorText('')
    }
  }
  const submitClicked = () => {
    console.info({ textField, selectField })
  }

  return (
    <Box w="100%">
      <Gap />

      <TextInput
        placeholder="enter text"
        label="Text Example"
        value={textField}
        errorText={textFieldErrorText}
        onBlur={(val) => validateTextField(val)}
        onChange={setTextField}
      />

      <Gap s="48px" />

      <TimeInput
        label="Time Example"
        value={{ hours: timeHours, minutes: timeMinutes }}
        errorText={timeFieldErrorText}
        onBlur={(val) => validateTimeField(val)}
        onChange={(val) => {
          if (val) {
            setTimeHours(val.hours);
            setTimeMinutes(val.minutes)
          }
        }}
      />

      <Gap s="48px" />

      <SelectInput
        placeholder="select an option"
        label="Select Example"
        options={[
          { label: 'option 1', value: '1' },
          { label: 'option 2', value: '2' },
        ]}
        value={selectField}
        errorText={selectFieldErrorText}
        onBlur={(val) => validateSelectField(val)}
        onChange={setSelectField}
      />

      <Gap s="48px" />

      <PhoneInput
        label="Phone Example"
        value={phoneField}
        errorText={phoneFieldErrorText}
        onChange={setPhoneField}
        onError={setPhoneFieldErrorText}
      />

      <Gap s="48px" />

      <PasswordInput
        placeholder="enter password"
        label="Password Example"
        value={passwordField}
        errorText={passwordFieldErrorText}
        onBlur={(val) => validatePasswordField(val)}
        onChange={setPasswordField}
      />

      <Gap s="48px" />
      <Text>Radio Input Example</Text>
      <Gap/>
      <RadioGroupInput
        value={radioField}
        errorText={radioFieldErrorText}
        onChange={(option) => {setRadioField(option); validateRadioField(option)}}
        options={[{label: 'label', value: 'value'}, {label: 'label2', value: 'value2'}]}
      />

      <Gap/>
      <Text>Checkbox Input Example</Text>
      <Gap/>
      <CheckboxGroupInput
        selectedOptions={checkboxField}
        errorText={checkboxFieldErrorText}
        onChange={(options) => {setCheckField(options); validateCheckField(options)}}
        options={[{label: 'label', value: 'value'}, {label: 'label2', value: 'value2'}]}
      />
      <Gap s="48px" />

      <Button onClick={() => submitClicked()}>Submit</Button>
    </Box>
  )
}
