import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { SignupFormLayout } from './SignupFormLayout'
import { Gap } from '../../../mini-lib/gap/Gap'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { APILaraPatchSalon } from '../../../data/salon/interfaces'
import { dispatchLaraPatchSalon } from '../../../data/salon/api'
import { Redirect } from 'react-router-dom'
import { ROUTES } from '../../../appRoutes'
import { CheckboxGroupInput, CheckboxOption } from '../../../mini-lib/form/CheckboxGroupInput'

export const SignupSalonProblemsPage = () => {
  return (
    <SignupFormLayout stepNumber={3} totalSteps={4}>
      <Flex justify="center" align="center" direction="column">
        <Gap />
        <Text variant="title2-desktop-bold">What do you need help with?</Text>
        <Gap s="6px" />
        <Text variant="callout-desktop-regular">Select all that apply</Text>
        <Gap s="32px" />
        <SalonProblemsForm />
      </Flex>
    </SignupFormLayout>
  )
}

const checkboxSelectOptions: CheckboxOption[] = [
  { label: 'Become profitable in a scalable way', value: 'profit' },
  { label: 'Transparency of my backbar costs', value: 'backbar costs' },
  { label: 'Business insights to drive decisions', value: 'data driven' },
  { label: 'Retain my top stylists', value: 'retain stylists' },
]

const SalonProblemsForm = () => {
  const dispatch = useDispatch()
  const { user } = UseBaseApiParams()

  const [selectedOptions, setSelectedOptions] = useState<CheckboxOption[]>([])
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState<string>('')

  const submitClicked = () => {
    if (selectedOptions.length === 0) {
      setError('Please select an option to continue!')
      return
    }
    const body: APILaraPatchSalon = {
      signup_answer_problems: selectedOptions.map((option) => option.value).join(','),
    }

    dispatch(
      dispatchLaraPatchSalon({
        token: user.token,
        userId: user.userId,
        currentSalonContext: user.currentSalonContext,
        salonId: user.currentSalonContext?.salonId || -1,
        request: body,
        syncToPipedrive: true,
      }),
    )
    setSubmitted(true)
  }
  if (submitted) {
    return <Redirect to={ROUTES.signupV2PartsAndLabor} />
  }
  return (
    <Box w="100%">
      <Box p="24px">
        <CheckboxGroupInput
          onChange={(options) => {
            setSelectedOptions(options)
          }}
          options={checkboxSelectOptions}
          selectedOptions={selectedOptions}
          errorText={error}
          theme="lavender"
        />
      </Box>

      <Box p="0 12px">
        <Button variant="round-large" colorScheme="brand.midnight" w="100%" onClick={() => submitClicked()}>
          Continue
        </Button>
      </Box>
      <Gap/>
    </Box>
  )
}
