import {BulkMatches, CSVColor} from "./interfaces";
import {assign, has, keyBy, mapValues, reduce} from "lodash";
import {MasterProduct} from "../master-products/interfaces";

export const mergeMatchesAndRows = (
    rows: CSVColor[],
    matches: BulkMatches | null
): CSVColor[] => {
  if (matches === null) {
    return rows
  }
  const vendorNameToIdMap = mapValues(keyBy(matches.vendors, v => v.name.toLowerCase()), 'id')
  const lineNameToIdMap = mapValues(keyBy(matches.lines, l => l.name.toLowerCase()), 'id')
  const typeNameToIdMap = mapValues(keyBy(matches.types, t => t.name.toLowerCase()), 'id')
  const categoryNameToIdMap = mapValues(keyBy(matches.categories, c => c.name.toLowerCase()), 'id')

  const updatedRows: CSVColor[] = rows.map((row: CSVColor) => {
    const hasVendor = vendorNameToIdMap && !!vendorNameToIdMap[row.vendor?.toLowerCase()]
    const hasLine = lineNameToIdMap && !!lineNameToIdMap[row.line?.toLowerCase()]
    const hasCategory = categoryNameToIdMap && !!categoryNameToIdMap[row.category?.toLowerCase()]
    const hasType = typeNameToIdMap && !!typeNameToIdMap[row.type?.toLowerCase()]
    const matchesMasterColor = hasVendor && hasLine && hasCategory && hasType
    return assign({}, row, {matchesMasterColor})
  })
  return updatedRows
}

export const mergeCreatedColorsWithCsvRows = (params: {
  rows: CSVColor[],
  masterProducts: MasterProduct[],
}): CSVColor[] => {
  const {rows, masterProducts} = params
  const mergedRows: CSVColor[] = []

  if (rows) {
    rows.forEach((row) => {
      const matches = masterProducts ? getMasterProductsThatMatchCsvRow(row, masterProducts) : []
      const updatedRow = assign({}, row, { id: matches.length > 0 ? matches[0].id : null })
      mergedRows.push(updatedRow)
    })
  }
  return mergedRows
}
export const getMasterProductsThatMatchCsvRow = (row: any, masterProducts: MasterProduct[]): MasterProduct[] => {
  return masterProducts.filter((mp) => masterProductMatchesCsvRow(row, mp)) || []
}

// returns true if they are an exact match
export const masterProductMatchesCsvRow = (row: CSVColor, masterProduct: MasterProduct): boolean => {
  const hasBrand = has(row, 'line') && row.line.toLowerCase() === masterProduct.brandName.toLowerCase()
  const hasParentBrand = has(row, 'vendor') && row.vendor.toLowerCase() === masterProduct.parentBrandName.toLowerCase()
  const hasCategory = has(row, 'category') && row.category.toLowerCase() === masterProduct.category.toLowerCase()
  const hasType = has(row, 'type') && row.type.toLowerCase() === masterProduct.type.toLowerCase()
  const hasSize = has(row, 'size') && row.size.toString() === masterProduct.size.toString()
  return hasBrand && hasParentBrand && hasCategory && hasType && hasSize
}

export const mapUploadErrors = (uploadErrors: any): { [key: number]: string[] } | null => {
  if (!uploadErrors) {
    return null
  }
  return reduce(uploadErrors, (result, messages, key) => {
    const match = key.match(/^master_products\.(\d+)/) // extract index
    if (match) {
      const [, index, field] = match

      const formattedMessages = messages.map(msg =>
        msg.replace(`master_products.${index}.`, '').replace(field, `${field} field`) // clean the message
      )
      result[index] = (result[index] || []).concat(formattedMessages)
    }
    return result
  }, {})
}