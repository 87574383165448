import { UseBaseApiParams } from "../../../../core/UseBaseApiParams";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { Gap } from "../../../../mini-lib/gap/Gap";
import { SelectInput } from "../../../../mini-lib";
import { generatePath, Link } from "react-router-dom";
import { ROUTES } from "../../../../appRoutes";
import React, { ReactNode, useEffect } from "react";
import { CONTENT_IDS } from "./LaborTeamOnboardingPage";
import { COLORS } from "../../../../mini-lib/theme/colors";
import { ManageTiers } from "../../LegacyLaborPage";
import { dispatchListLaborTiers } from "../../../../data/labor-legacy/api";
import { useDispatch } from "react-redux";
import { UseCreateLaborTiersForStartGuide } from "../../../../data/labor-legacy/hooks";
import { stringNumberSort } from "../../../../mini-lib/utils/sorting";
import { DEFAULT_TIER_NAME } from "../../../../data/labor-legacy/constants";
import { LaborTierCell } from "../../LegacyLaborTableCells";
import { useAppSelector } from "../../../../hooks";
import { selectLaborTierList } from "../../../../data/labor-legacy/slice";
import { TableCell } from "../../../../mini-lib/table/Table";
import { dispatchListSalonUsers } from "../../../../data/salon-user/api";
import { SalonUserTierIcon } from "../../LegacyLaborTierUsers";

export const LaborOnboardCustomizeMenuContent = (props: {
  laborServiceType: string,
  setLaborServiceType: (type: string) => void,
  actions: ReactNode
}) => {
  const { actions, laborServiceType, setLaborServiceType } = props
  return (
    <Flex justify="center" direction="column" align="center">
      <Flex align="center" justify="center" direction="column" gridGap="24px" maxW="750px" textAlign="center">
        <Gap />
        <Text variant="title1" fontWeight="bold">
          Let’s customize your menu...
        </Text>

        <Text>Here is where you can really customize things the way you like.</Text>

        <Text>
          We have provided a basic template to get you started. This template is completely editable and customizable
          just follow along with the tips provided on the next page.
        </Text>

        <Text>
          But first, please select if you typically charge for your services by the hour or if you use a flat rate.
          {/*todo: add learn more here when i have a link*/}
          {/*learn more*/}
        </Text>
      </Flex>

      <Gap />
      <Gap />

      <Box w="500px" maxW="100%">
        <Text variant="callout-desktop-regular">Default Charge Type (this can be adjusted later)</Text>
        <Gap s="12px" />
        <SelectInput
          variant="material"
          isClearable={false}
          options={[
            { value: 'hourly', label: 'Hourly Rate' },
            { value: 'service', label: 'Flat Rate Service' },
          ]}
          value={laborServiceType}
          onChange={(optionValue) => setLaborServiceType(optionValue)}
        />
      </Box>
      <Gap />
      <Gap />
      {actions}
    </Flex>
  )
}


export const LaborOnboardHasTiersContent = (props: { hasTiers: boolean; setHasTiers: (has: boolean) => void, setContentId: (id: string) => void }) => {
  const { hasTiers, setHasTiers, setContentId } = props
  const {salonId} = UseBaseApiParams()
  return (
    <Flex justify="center" direction="column" align="center">
      <Flex align="center" justify="center" direction="column" gridGap="24px" maxW="750px" textAlign="center">
        <Gap />
        <Text variant="title1" fontWeight="bold">
          First, DO You Use a Tiered pricing/Level System to Price your Services?
        </Text>

        <Box
          w="100%" borderRadius='100px'
          bg={hasTiers ? COLORS.skylight_50 : ''}
          border={hasTiers ? `1px solid ${COLORS.skylight_500}` : `1px solid ${COLORS.border}`}
          p='12px'
          onClick={() => setHasTiers(true)}
          cursor='pointer'
        >
          Yes, my salon uses a level system to charge based on stylist experience
        </Box>

        <Box
          w="100%"
          borderRadius='100px'
          bg={!hasTiers ? COLORS.skylight_50 : ''}
          border={!hasTiers ? `1px solid ${COLORS.skylight_500}` : `1px solid ${COLORS.border}`}
          p='12px'
          onClick={() => setHasTiers(false)}
          cursor='pointer'
        >
          No, my salon does not use a level system
        </Box>

      </Flex>

      <Gap />
      <Gap/>
      <Flex justify='end' gridGap='12px' maxW='100%' w='500px'>
        <Link to={generatePath(ROUTES.sgLaborIntro, { salonId })}>
          <Button variant='round-outline' colorScheme='brand.midnight'>Back</Button>
        </Link>
        <Button onClick={() => setContentId(hasTiers ? CONTENT_IDS.tierCreate : CONTENT_IDS.menu)} variant='round' colorScheme='brand.midnight'>
          Continue
        </Button>
      </Flex>
    </Flex>
  )
}

export const LaborOnboardTierCreateContent = (props: { setContentId: (id: string) => void }) => {
  const dispatch = useDispatch()
  const { setContentId } = props
  const {salonId, user} = UseBaseApiParams()

  useEffect(() => {
    dispatch(dispatchListLaborTiers({token: user.token, salonId}))
  }, [dispatch, user.token, salonId])

  // add basic tiers for new users
  UseCreateLaborTiersForStartGuide()

  return (
    <Flex justify="center" direction="column" align="center">
      <Flex align="center" justify="center" direction="column" gridGap="24px" maxW="750px" textAlign="center">
        <Gap />
        <Text variant="title1" fontWeight="bold">
          Select the number of levels you use, then label them
        </Text>
      </Flex>
      <Gap/>
      <ManageTiers onBack={() => setContentId(CONTENT_IDS.hasTier)} onContinue={() => setContentId(CONTENT_IDS.tierAssign)}/>
    </Flex>
  )
}

export const LaborOnboardTierAssignContent = (props: { setContentId: (id: string) => void }) => {
  const dispatch = useDispatch()
  const { setContentId } = props
  const {salonId, user} = UseBaseApiParams()
  const tiers = useAppSelector(selectLaborTierList)
  useEffect(() => {
    dispatch(dispatchListLaborTiers({token: user.token, salonId}))
  }, [dispatch, user.token, salonId])
  useEffect(() => {
    dispatch(dispatchListSalonUsers({token: user.token, salonId}))
  }, [dispatch, user.token, salonId])


  return (
    <Flex justify="center" direction="column" align="center">

      <Flex align="center" justify="center" direction="column" gridGap="24px" maxW="750px" textAlign="center">
        <Gap />
        <Text variant="title1" fontWeight="bold">
          Let’s assign your team to their levels
        </Text>
        <Gap/>
        <Flex gridGap='4px'>Click the <SalonUserTierIcon/> icon to add a stylist to the selected level. </Flex>
      </Flex>

      <Gap/>

      <Flex gridGap='12px'>
        {tiers && stringNumberSort(tiers, 'name', DEFAULT_TIER_NAME).map((tier, index) => {
          return (
            <TableCell key={tier.id}>
              <LaborTierCell tier={tier} tierIndex={index} categoryIndex={-1}/>
            </TableCell>
          )
        })}
      </Flex>

      <Gap/>

      <Flex justify='end' gridGap='12px' maxW='100%' w='500px'>
        <Button onClick={() => setContentId(CONTENT_IDS.tierCreate)} variant='round-outline' colorScheme='brand.midnight'>Back</Button>
        <Button onClick={() => setContentId(CONTENT_IDS.menu)} variant='round' colorScheme='brand.midnight'>
          Continue
        </Button>
      </Flex>
    </Flex>
  )
}
