/////////////// mappers.ts
//
//

import { map } from 'lodash'
import {
  APILabor, Labor,
} from './interfaces'
import {mapAPISuppliesToSupplies} from "../supplies/mappers";


export const mapAPILaborToLabor = (api: APILabor): Labor => {
  return {
    id: api.id,
    salonId: api.salon_id,
    userId: api.user_id,
    name: api.name,
    category: api.category,
    colorhex: api.colorhex,
    durationSeconds: api.duration_seconds,
    supplies: mapAPISuppliesToSupplies(api.supplies),
  }
}

export const mapAPILaborsToLabors = (apiModels: APILabor[]): Labor[] => {
  return map(apiModels, mapAPILaborToLabor)
}

