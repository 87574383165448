import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Button, Center, Divider, Flex, Spinner, Text, Textarea } from '@chakra-ui/react'
import { useAppSelector } from '../../hooks'
import {
  reduceRedirectToSessionId,
  reduceSetActiveSessionDisplayUnit,
  selectActiveSessionColors,
  selectActiveSessionDisplayUnit,
  selectActiveSessionMeta,
  selectActiveSessionTotalCents,
  selectRedirectToSessionId
} from '../../data/sessions/slice'

import { PageHeader } from '../../mini-lib/page-header/PageHeader'
import { PageLayout } from '../../mini-lib/layouts/PageLayout'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { UseViewSize } from '../../core/UseViewSize'
import { generatePath, Link, useHistory, useParams } from 'react-router-dom'
import {
  dispatchCompleteSessionLara,
  dispatchDeleteSessionMetaLara,
  dispatchGetSessionMetaLara,
  dispatchListSessionColorsLara,
  dispatchListSessionExtensionsLara,
  dispatchListSessionSuppliesLara,
  dispatchPatchSessionMetaLara,
} from '../../data/sessions/api'
import { APISessionMetaPatchLara, DisplayUnit, SessionColorLara, SessionMetaLara } from '../../data/sessions/interfaces'
import { MaterialIcon } from '../../mini-lib/icons/MaterialIcon'
import { EmptyBox } from '../../mini-lib/empty/EmptyBox'
import { COLORS } from '../../mini-lib/theme/colors'
import { SessionSupplies } from './session-supplies/SessionSupplies'
import { BowlFormula, SessionBowls } from './session-colors/SessionBowls'
import { SessionMetaDetails } from './SessionMetaDetails'
import { SessionExtensions } from './session-extensions/SessionExtensions'
import { SessionPhotos } from './SessionPhotos'
import { SESSION_STATUS_OPTIONS } from '../../data/sessions/constants'
import { ConfirmPopover } from '../../mini-lib/confirm-popover/ConfirmPopover'
import {
  getBowlMapLara,
  getBowlTitleLara,
  getSessionColorsByBowlLara,
  getTotalSessionColorCostLara,
} from '../../data/sessions/utils'

import { formatCentsToDollars } from "../../mini-lib/units/money";
import { ROUTES } from "../../appRoutes";
import {ReleaseLabour, ReleaseOpenDay} from "../../mini-lib/flags/Release";
import {SessionLabor} from "./session-labor/SessionLabor";
import {dispatchListSessionLaborItemsLara} from "../../data/sessions/session-labor/api";
import { getLocalStorageItem } from '../../core/localStorage'

export const SessionDetailsPage = () => {
  const { user, salonId } = UseBaseApiParams()
  const linkUrl = generatePath(ROUTES.sessions, { salonId })
  const breadcrumbs = [
    { label: 'Home', url: generatePath(ROUTES.home, { salonId }) },
    { label: 'Sessions', url: linkUrl },
    { label: 'Details' },
  ]
  const dispatch = useDispatch()
  const history = useHistory()
  const params: any = useParams()
  const { sessionId } = params
  const { isMobile } = UseViewSize()
  const redirectToSessionId = useAppSelector(selectRedirectToSessionId)
  const releaseOpenDay = ReleaseOpenDay()

  useEffect(() => {
    dispatch(reduceSetActiveSessionDisplayUnit(getLocalStorageItem('unit') ?? 'g'))
  }, [dispatch])

  // reset the redirect id if the user came here from creating a session
  useEffect(() => {
    if (redirectToSessionId !== null) {
      dispatch(reduceRedirectToSessionId(null))
    }
  }, [dispatch, redirectToSessionId])

  // lara session apis
  //
  //
  useEffect(() => {
    if (user.token && salonId && sessionId) {
      dispatch(dispatchListSessionColorsLara({ token: user.token, salonId, sessionId }))
    }
  }, [dispatch, user.token, salonId, sessionId])

  useEffect(() => {
    if (user.token && salonId && sessionId) {
      dispatch(dispatchListSessionExtensionsLara({ token: user.token, salonId, sessionId }))
    }
  }, [dispatch, user.token, salonId, sessionId])

  useEffect(() => {
    if (user.token && salonId && sessionId) {
      dispatch(dispatchListSessionSuppliesLara({ token: user.token, salonId, sessionId }))
    }
  }, [dispatch, user.token, salonId, sessionId])

  const sessionMeta = useAppSelector(selectActiveSessionMeta)

  useEffect(() => {
    if (user.token && salonId && sessionId) {
      dispatch(dispatchGetSessionMetaLara({ token: user.token, salonId, sessionId }))
    }
  }, [dispatch, user.token, salonId, sessionId])

  useEffect(() => {
    if (user.token && salonId && sessionId) {
      dispatch(dispatchListSessionLaborItemsLara({ token: user.token, salonId, sessionId }))
    }
  }, [dispatch, user.token, salonId, sessionId])

  const releaseLabour = ReleaseLabour()
  const patchSessionMeta = (updatedProperties: APISessionMetaPatchLara) => {
    dispatch(dispatchPatchSessionMetaLara({ token: user.token, salonId, sessionId, model: updatedProperties }))
  }
  //
  //
  //////

  return (
    <PageLayout
      variant="full"
      header={
        <PageHeader
          title={sessionMeta && sessionMeta.status === SESSION_STATUS_OPTIONS.complete ? 'View Session' : 'Edit Session'}
          breadcrumbs={breadcrumbs}
          actions={
            <Flex gridGap="12px">
              {/*<Link to={generatePath(ROUTES.sessions, { salonId })}>*/}
              {/*  <Button variant="round-ghost-upper">Back</Button>*/}
              {/*</Link>*/}
              <ConfirmPopover
                title={`This Action is Permanent`}
                subtitle={`Removing this session means it will no longer appear in your salon.`}
                onConfirm={() => {
                  if (sessionMeta) {
                    // dispatch(dispatchDeleteSession({ token: user.token, salonId, model }))
                    dispatch(dispatchDeleteSessionMetaLara({token: user.token, salonId, sessionId}))

                    const linkUrl = releaseOpenDay ? generatePath(ROUTES.sessionsByMember, { salonId }) : generatePath(ROUTES.sessions, { salonId })
                    history.push(linkUrl)
                  }
                }}
              >
                <Button w="100px" variant="round-ghost-upper" color="danger" cursor="pointer">
                  {isMobile ? 'Delete' : 'Delete Session'}
                </Button>
              </ConfirmPopover>
              {sessionMeta && <SessionStatusButton sessionMeta={sessionMeta} />}
            </Flex>
          }
        />
      }
      content={
        <>
          {sessionMeta && (
            <>
              <Box h={isMobile ? '' : '48px'} />
              <Flex justify="space-between" gridGap="24px" flexWrap="wrap">
                <Box w={isMobile ? '100%' : '60%'}>
                  <SessionMetaDetails sessionMeta={sessionMeta} patchSessionMeta={patchSessionMeta}/>
                  <Box h="48px" />
                  <SessionBowls sessionMeta={sessionMeta} />
                  <Box h="48px" />
                  <SessionExtensions sessionMeta={sessionMeta} /> 
                  <Box h="48px" />
                  <SessionSupplies sessionMeta={sessionMeta} />
                  <Box h="48px" />
                  {releaseLabour && <SessionLabor sessionMeta={sessionMeta} />}
                </Box>
                <Box w={isMobile ? '100%' : '34%'}>
                  <SessionTotal />
                  <Box h="24px" />
                  <Divider />
                  <Box h="24px" />
                  <SessionWaste />

                  <SessionNotes sessionMeta={sessionMeta} patchSessionMeta={patchSessionMeta}/>
                  <Box h="24px" />
                  <Divider />
                  <Box h="24px" />
                  <SessionPhotos sessionMeta={sessionMeta}/>
                  <Box h="24px" />
                </Box>
              </Flex>
            </>
          )}
          {!sessionMeta && (
            <Flex h="500px" align="center" justify="center">
              <Spinner />
            </Flex>
          )}
        </>
      }
    />
  )
}
export const SessionTotal = () => {
  const sessionTotal = useAppSelector(selectActiveSessionTotalCents)
  return (
    <Flex justify="space-between">
      <Text variant="title1" fontWeight="bold">
        Session total
      </Text>
      <Text variant="title1" fontWeight="bold">
        ${formatCentsToDollars(sessionTotal)}
      </Text>
    </Flex>
  )
}

export const SessionWaste = () => {

  // lara session apis
  //
  //
  const sessionColors = useAppSelector(selectActiveSessionColors)
  const displayUnit = useAppSelector(selectActiveSessionDisplayUnit)

  // todo: consider putting this in a useEffect with sessionColors as the dep
  const bowlMapLara = sessionColors ? getBowlMapLara(sessionColors) : null
  const bowlListLara = bowlMapLara ? getSessionColorsByBowlLara(bowlMapLara) : null
  // const bowlColors = bowlMapLara && selectedBowlId ? bowlMapLara[selectedBowlId] : []


  const wasteTotal = sessionColors ? getTotalSessionColorCostLara(sessionColors, 'waste') : 0
  return (
    <>
      {!!wasteTotal && (
        <>
          <Flex justify="space-between">
            <Text variant="title3" fontWeight="bold">
              Waste
            </Text>
            <Text variant="title3" fontWeight="bold">
              ${formatCentsToDollars(wasteTotal)}
            </Text>
          </Flex>
          <Box h="12px" />
          {bowlListLara?.map((bowl, index) => {
            return <SessionBowlWaste key={index} displayUnit={displayUnit} bowlColors={bowl} />
          })}
          <Box h="12px" />
          <Divider />
          <Box h="24px" />
        </>
      )}
    </>
  )
}

export const SessionBowlWaste = (props: { bowlColors: SessionColorLara[], displayUnit: DisplayUnit }) => {
  const { bowlColors, displayUnit } = props
  const bowlWasteTotal = getTotalSessionColorCostLara(bowlColors, 'waste')
  const sessionItemsWithWaste = bowlColors.filter((item) => item.wasteGrams && item.wasteGrams > 0)
  const { bowlType, bowlTag } = getBowlTitleLara(bowlColors[0])
  return (
    <>
      {sessionItemsWithWaste.length > 0 && (
        <>
          <Box h="8px" />
          <Text variant="footnote">{bowlType} {bowlType && bowlTag && '•'} {bowlTag} Waste</Text>
          <Flex align="center" justify="space-between" gridGap="12px">
            <Flex align="center">
              <BowlFormula bowlColors={bowlColors} displayUnit={displayUnit} bowlView="waste" />
            </Flex>
            <Text variant="footnote">${formatCentsToDollars(bowlWasteTotal)}</Text>
          </Flex>
          <Box h="12px" />
        </>
      )}
    </>
  )
}

export const SessionNotes = (props: {sessionMeta: SessionMetaLara | null, patchSessionMeta: (props: APISessionMetaPatchLara) => void }) => {
  const { sessionMeta, patchSessionMeta } = props
  const [notes, setNotes] = useState(sessionMeta?.notes || '')
  const [isEditable, setIsEditable] = useState(false)
  const existingNote = !!sessionMeta?.notes && sessionMeta?.notes !== ''
  return (
    <Box>
      <Text variant="title3">Notes</Text>
      <Box h="24px" />
      {existingNote && !isEditable && (
        <>
          <Text onClick={() => setIsEditable(true)}>{sessionMeta?.notes}</Text>
          <Flex align="center" p="12px 0" onClick={() => setIsEditable(true)} cursor="pointer">
            <MaterialIcon colorhex={COLORS.lavender_500} name="add_circle" style={{ marginRight: '4px' }} />{' '}
            <Text color="brand.lavender.500">Edit Note</Text>
          </Flex>
        </>
      )}
      {!existingNote && !isEditable && (
        <EmptyBox h="100px" title="No Notes" content="nothing to display">
          {sessionMeta?.status === SESSION_STATUS_OPTIONS.open && (
            <Flex align="center" p="12px 0" onClick={() => setIsEditable(true)} cursor="pointer">
              <MaterialIcon colorhex={COLORS.lavender_500} name="add_circle" style={{ marginRight: '4px' }} />{' '}
              <Text color="brand.lavender.500">add note</Text>
            </Flex>
          )}
        </EmptyBox>
      )}
      {isEditable && (
        <>
          <Textarea value={notes} onChange={(e) => setNotes(e.target.value)} />
          <Flex gridGap="12px" justify="flex-end" p="12px 0">
            <Button variant="round-outline" onClick={() => setIsEditable(false)}>
              Cancel
            </Button>
            <Button
              variant="round"
              onClick={() => {
                setIsEditable(false)
                patchSessionMeta({ notes })
              }}
            >
              Update Note
            </Button>
          </Flex>
        </>
      )}
    </Box>
  )
}

export const SessionStatusButton = (props: {
  sessionMeta: SessionMetaLara
}) => {
  const { user, salonId } = UseBaseApiParams()
  const dispatch = useDispatch()
  const { sessionMeta: { status, id } } = props
  const releaseOpenDay = ReleaseOpenDay()
  const linkUrl = releaseOpenDay ? generatePath(ROUTES.sessionsByMember, { salonId }) : generatePath(ROUTES.sessions, { salonId })
  return (
    <>
      {status === SESSION_STATUS_OPTIONS.open && (
        <Link to={linkUrl}>
          <Button
            colorScheme="brand.lavender"
            variant="round"
            onClick={() => {
              dispatch(dispatchCompleteSessionLara({ token: user.token, sessionId: id, salonId }))
            }}
          >
            Complete Session
          </Button>
        </Link>
      )}
      {status === SESSION_STATUS_OPTIONS.complete && (
        <Center h="40px">
          <Text variant="button" color={COLORS.shades_neutral_400}>
            SESSION COMPLETE
          </Text>
        </Center>
        // <Button
        //   colorScheme="brand.lavender"
        //   variant="round-outline"
        //   onClick={() => updateSession({ status: SESSION_STATUS_OPTIONS.open })}
        // >
        //   Edit Session
        // </Button>
      )}
    </>
  )
}
