import {CategoryAndColor, Labor} from "../../../data/labor/interfaces";
import {useDispatch} from "react-redux";
import {UseBaseApiParams} from "../../../core/UseBaseApiParams";
import React, {useState} from "react";
import {convertSecondsToHoursMinutes, TimeInput} from "../../../mini-lib/form/TimeInput";
import {COLORS} from "../../../mini-lib/theme/colors";
import {dispatchDeleteLabors, dispatchUpsertLabors} from "../../../data/labor/api";
import {useAppSelector} from "../../../hooks";
import {selectSalonUsers} from "../../../data/salon-user/slice";
import {Box, Button, Divider, Flex, Switch, Text} from "@chakra-ui/react";
import {SupplySelectSheet} from "../../session-details/session-supplies/SupplySelectSheet";
import {TextInput} from "../../../mini-lib";
import {Gap} from "../../../mini-lib/gap/Gap";
import {Loading} from "../../../mini-lib/loading/Loading";
import {buildLoadingLaborId, getLaborModelsForUpsertAndDelete} from "../../../data/labor/utils";
import {selectLoadingState} from "../../../core/loading/slice";

export const LaborForm = (props: {
  formType: 'create' | 'update'
  laborTemplate: Labor | null
  labors: Labor[] | null
  categoryAndColor: CategoryAndColor
  onClose: () => void
}) => {
  const { laborTemplate, labors, categoryAndColor, onClose, formType } = props
  const dispatch = useDispatch()
  const { user, salonId } = UseBaseApiParams()

  const [nameField, setNameField] = useState(laborTemplate?.name || '')
  const [nameFieldErrorText, setNameFieldErrorText] = useState('')
  const validateTextField = (textFieldVal: string) => {
    if (textFieldVal.length < 2) {
      setNameFieldErrorText('please enter at least two characters')
    } else {
      setNameFieldErrorText('')
    }
  }

  const initialSelectedUserIds = labors ? labors.map((labor) => labor.userId) : []
  const [selectedUserIds, setSelectedUserIds] = useState<number[]>(initialSelectedUserIds)

  const hoursMinutes = convertSecondsToHoursMinutes(laborTemplate ? laborTemplate.durationSeconds : 0)
  const [showAdditional, setShowAdditional] = useState(false)
  const [timeHours, setTimeHours] = useState<number | null>(hoursMinutes.hours)
  const [timeMinutes, setTimeMinutes] = useState<number | null>(hoursMinutes.minutes)
  const [timeFieldErrorText, setTimeFieldErrorText] = useState('')
  const validateTimeField = (timeFieldVal: { hours: number | null; minutes: number | null }) => {
    if (!timeFieldVal.hours && !timeFieldVal.minutes) {
      setTimeFieldErrorText('please enter at least 1 minute ')
    } else {
      setTimeFieldErrorText('')
    }
  }
  const [showSupplySheet, setShowSupplySheet] = useState(false)
  const initialSupplies = laborTemplate ? laborTemplate.supplies : []
  const [selectedSupplies, setSelectedSupplies] = useState(initialSupplies)

  const onUpsertService = () => {

    const {upsertModels, deleteModels} = getLaborModelsForUpsertAndDelete({
      salonId,
      formType,
      existingLabors: labors,
      name: nameField,
      colorhex: categoryAndColor.colorhex,
      category: categoryAndColor.category,
      timeHours,
      timeMinutes,
      supplyIds: selectedSupplies.map(supply => supply.id),
      userIds: selectedUserIds,
    })

    if (deleteModels.length > 0) {
      dispatch(
        dispatchDeleteLabors({
          token: user.token,
          userId: user.userId,
          salonId: salonId,
          models: deleteModels,
        }),
      )
    }

    if (upsertModels.length > 0) {
      const loadingId = buildLoadingLaborId(laborTemplate ? laborTemplate.id : 0)
      dispatch(
        dispatchUpsertLabors({
          token: user.token,
          loadingId,
          userId: user.userId,
          salonId: salonId,
          models: upsertModels,
        }),
      )
    }
    onClose()
  }
  const salonUsers = useAppSelector(selectSalonUsers)
  const loadingId = buildLoadingLaborId(0)
  const creatingLabor = useAppSelector((state) => selectLoadingState(state, loadingId))

  return (
    <Box>
      {showSupplySheet && (
        <SupplySelectSheet
          selectedSupplyIds={selectedSupplies.map(supply => supply.id)}
          show={showSupplySheet}
          onHide={() => setShowSupplySheet(false)}
          onSuppliesSelected={(supplies) => {
            setSelectedSupplies(supplies)
            setShowSupplySheet(false)
          }}
        />
      )}
      <TextInput
        variant="material"
        placeholder="enter text"
        label="Service Name"
        value={nameField}
        errorText={nameFieldErrorText}
        onBlur={(val) => validateTextField(val)}
        onChange={setNameField}
      />
      <Gap />
      <TimeInput
        label="Duration"
        value={{ hours: timeHours, minutes: timeMinutes }}
        errorText={timeFieldErrorText}
        onBlur={(val) => validateTimeField(val)}
        onChange={(val) => {
          if (val) {
            setTimeHours(val.hours)
            setTimeMinutes(val.minutes)
          }
        }}
      />
      <Gap />
      {!showAdditional && <Text cursor='pointer' color={COLORS.lavender_500} onClick={() => setShowAdditional(true)}>+ additional options</Text>}
      {showAdditional && (
        <>
          <Divider />

          <Gap />
          <Text fontWeight="bold">Pre-set add-ons</Text>
          <Text color={COLORS.text_secondary}>Select add-ons to be automatically added to new sessions</Text>
          <Gap />
            {selectedSupplies.map(supply => {
              return (
                <Text mb="12px">{supply.name}</Text>
              )
            })}
          <Flex justify='flex-end'>
            <Button variant="round-ghost-lower" colorScheme="brand.lavender" onClick={() => setShowSupplySheet(true)}>
              + Add-ons
            </Button>
          </Flex>
          <Gap />

          <Divider />

          <Gap />
          <Text fontWeight="bold">Staff ({salonUsers ? salonUsers.length : <Loading />})</Text>
          {formType === 'create' && (
            <Text color={COLORS.text_secondary}>Configure which staff can see this service</Text>
          )}
          <Gap />
          {salonUsers ? (
            salonUsers.map((user) => {
              const isSelected = selectedUserIds.includes(user.userId)
              return (
                <Flex key={user.id} justify="space-between" align="center" mb="12px">
                  <Box>
                    {user.firstName} {user.lastName}
                  </Box>
                  <Switch
                    isChecked={isSelected}
                    size="md"
                    colorScheme="brand.midnight"
                    onChange={() => {
                      if (isSelected) {
                        setSelectedUserIds(selectedUserIds.filter((id) => id !== user.userId))
                      } else {
                        setSelectedUserIds([...selectedUserIds, user.userId])
                      }
                    }}
                  />
                </Flex>
              )
            })
          ) : (
            <Loading />
          )}
          <Gap />
          <Text cursor='pointer' color={COLORS.lavender_500} onClick={() => setShowAdditional(false)}>- additional options</Text>
          <Gap />

        </>
      )}
      <Flex justify="flex-end" gridGap="12px">
        <Button colorScheme="brand.midnight" variant="round-outline" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="round" colorScheme="brand.midnight" onClick={onUpsertService}>
          {laborTemplate ? 'Update Service' : creatingLabor ? <Loading/> : 'Add Service'}
        </Button>
      </Flex>
    </Box>
  )
}
