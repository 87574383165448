import React, {ReactNode, useEffect, useRef, useState} from 'react'
import { Box, Flex, Text, Input } from '@chakra-ui/react'
import { InputError } from './InputError'
import { COLORS } from '../theme/colors'
import { uniqueId } from 'lodash'
import { ThemeType } from '../../theme'
import { MaterialLabel } from './MaterialHelpers'
import { BASE_INPUT_STYLES } from '../../styles'

export const TimeInput = (props: {
  label?: string | ReactNode
  placeholder?: string
  isDisabled?: boolean
  w?: string
  h?: string
  value: {hours: number | null, minutes: number | null}
  onChange?: (val: {hours: number | null, minutes: number | null}) => void
  onBlur?: (val: {hours: number | null, minutes: number | null}) => void
  errorText?: string
  theme?: ThemeType
  variant?: 'basic' | 'material'
  border?: 'normal' | 'rounded'
  allowFocus?: boolean
}) => {
  const { w, variant = 'material' } = props
  return (
    <Box w={w}>
      {variant === 'material' && <TimeInputBasic {...props} /> }
    </Box>
  )
}

export const TimeInputBasic = (props: {
  label?: string | ReactNode
  isDisabled?: boolean
  value: {hours: number | null, minutes: number | null}
  onChange?: (val: {hours: number | null, minutes: number | null}) => void
  onBlur?: (val: {hours: number | null, minutes: number | null}) => void
  errorText?: string
  theme?: ThemeType
  border?: 'normal' | 'rounded'
  h?: string
  w?: string
  allowFocus?: boolean
}) => {
  const {
    label,
    isDisabled = false,
    value,
    onChange = () => {},
    onBlur = () => {},
    errorText = '',
    theme = 'basic',
    border = 'rounded',
    h = '38px',
    w ='130px',
    allowFocus = false,
  } = props

  const [hours, setHours] = useState<null | number>(value.hours || null)
  const [minutes, setMinutes] = useState<null | number>(value.minutes || null)
  const [isFocused, setIsFocused] = useState(false)
  const [hasContent, setHasContent] = useState(value.hours !== null || value.minutes !== null)

  const elementId = uniqueId('formfield-')
  const themeColor = theme === 'basic' ? 'black' : COLORS[`${theme}_500`]
  const borderRadius = border === 'rounded' ? '50px' : '10px'

  useEffect(() => {
    setHasContent(value.hours !== null || value.minutes !== null)
    setHours(value.hours ?? 0)
    setMinutes(value.minutes ?? 0)
  }, [value.hours, value.minutes])

  const handleBlur = () => {
    setIsFocused(false)
    onBlur({hours, minutes})
  }

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleHoursChange = (e) => {
    const value = e.target.value.replace(/\D/g, '')
    setHours(value.slice(0, 2))
    onChange({hours: value.slice(0, 2), minutes})
  }

  const handleMinutesChange = (e) => {
    const value = e.target.value.replace(/\D/g, '') // Allow only digits
    setMinutes(value.slice(0, 2)) // Limit to 2 digits
    onChange({hours, minutes: value.slice(0, 2)})
  }

  const hoursInputRef = useRef<HTMLInputElement>(null)
  const minutesInputRef = useRef<HTMLInputElement>(null)
  return (
    <Box position="relative">
      {label && (
        <MaterialLabel
          allowFocus={allowFocus}
          label={label}
          elementId={elementId}
          isFocused={isFocused}
          isDisabled={isDisabled}
          hasContent={hasContent}
          themeColor={themeColor}
        />
      )}

      <Flex
        id={elementId}
        borderRadius={borderRadius}
        type="text"
        borderColor={!!errorText ? COLORS.danger : BASE_INPUT_STYLES.borderRegular}
        borderWidth={!!errorText ? '2px' : '1px'}
        _hover={{ borderColor: BASE_INPUT_STYLES.borderHover }}
        _focus={{
          borderColor: theme === 'basic' ? COLORS.shades_neutral_400 : themeColor,
          borderWidth: '2px',
        }}
        onBlur={handleBlur}
        onFocus={handleFocus}
        h={h}
        align="center"
        w={w}
        maxW={w}
      >
          <Input
            ref={hoursInputRef}
            value={hours || ''}
            onChange={handleHoursChange}
            isDisabled={isDisabled}
            placeholder="0"
            maxW="30px"
            textAlign="center"
            variant="unstyled"
            aria-label="Hours"
          />
          <Text
            cursor='pointer'
            onClick={() => {hoursInputRef.current?.focus(); hoursInputRef.current?.select()}}
            color={!hours ? COLORS.placeholder : ''}
          >
            hr
          </Text>
        :
          <Input
            ref={minutesInputRef}
            value={minutes || ''}
            isDisabled={isDisabled}
            onChange={handleMinutesChange}
            placeholder="0"
            maxW="30px"
            textAlign="center"
            variant="unstyled"
            aria-label="Minutes"
          />
          <Text
            cursor='pointer'
            onClick={() => {minutesInputRef.current?.focus(); minutesInputRef.current?.select()}}
            color={!minutes ? COLORS.placeholder : ''}
          >
            min
          </Text>
      </Flex>
      <InputError showError={!!errorText} errorText={errorText} />
    </Box>
  )
}

export const convertHoursMinutesToSeconds = (timeAndMinutes: {hours: number | null, minutes: number | null}): number => {
  const secondsFromHours = timeAndMinutes.hours ? timeAndMinutes.hours * 60 * 60 : 0
  const secondsFromMinutes = timeAndMinutes.minutes ? timeAndMinutes.minutes * 60 : 0
  return secondsFromHours + secondsFromMinutes
}

export const convertSecondsToHoursMinutes = (seconds: number): {hours: number | null, minutes: number | null} => {
  if (seconds <= 0) {
    return {hours: null, minutes: null}
  }

  const minuteLeftoverSeconds = seconds % 60
  const minuteSeconds = seconds % 3600 - minuteLeftoverSeconds
  const minuteExtraRounded = minuteLeftoverSeconds >= 30 ? 1 : 0
  const minutes = minuteSeconds / 60 + minuteExtraRounded

  const hourSeconds = seconds - minuteSeconds - minuteLeftoverSeconds
  const hours = hourSeconds  > 0 ? hourSeconds / 60 / 60 : null
  return {hours, minutes}
}
