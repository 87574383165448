/////////////// mappers.ts
//
//

import { isArray, map } from 'lodash'
import {
  APIMasterProduct,
  APIMasterProductLara,
  APIMasterProductTrialPrice,
  CSVMasterProduct,
  MasterProduct,
  MasterProductTrialPrice,
} from './interfaces'

export const mapAPIMasterProductToMasterProduct = (api: APIMasterProduct): MasterProduct => {
  return {
    id: api.id,
    parentBrandName: api.parent_brand_name,
    parentBrandLogoUrl: api.parent_brand_logo_url,
    brandName: api.brand_name,
    type: api.type,
    category: api.category,
    upcs: api.upcs ? api.upcs.map((upc) => upc.toString()) : [],
    size: api.size,
    units: api?.units ?? null
  }
}
export const mapAPIMasterProductsToMasterProducts = (apiModels: APIMasterProduct[]): MasterProduct[] => {
  return map(apiModels, mapAPIMasterProductToMasterProduct)
}

export const mapAPIMasterProductLaraToMasterProduct = (api: APIMasterProductLara): MasterProduct => {
  return {
    id: api.id,
    lineId: api.brand_id || api.line_id, // older api sends brand id
    categoryId: api.category_id,
    vendorId: api.vendor_id,

    type: api.name,
    brandName: api.line_name,
    parentBrandName: api.vendor_name,
    parentBrandLogoUrl: api.vendor_logo_url,

    category: api.category,
    level: api.level ?? null,
    volume: api.volume ?? null,

    size: api.size,
    units: api.units ?? null,
    upcs: api.upcs && isArray(api.upcs) ? api.upcs.map((upc) => upc.toString()) : [],
  }
}
export const mapAPIMasterProductsLaraToMasterProducts = (apiModels: APIMasterProductLara[]): MasterProduct[] => {
  return map(apiModels, mapAPIMasterProductLaraToMasterProduct)
}

export const mapAPIMasterProductTrialPriceToMasterProductTrialPrice = (
  api: APIMasterProductTrialPrice,
): MasterProductTrialPrice => {
  return {
    masterProductId: api.master_product_id,
    trialPrice: api.avg_salon_purchase_price,
  }
}
export const mapAPIMasterProductTrialPricesToMasterProductTrialPrices = (
  apiModels: APIMasterProductTrialPrice[],
): MasterProductTrialPrice[] => {
  return map(apiModels, mapAPIMasterProductTrialPriceToMasterProductTrialPrice)
}

export const mapMasterProductToCSVMasterProduct = (mp: MasterProduct): CSVMasterProduct => {
  return {
    id: mp.id,
    brand: mp.brandName,
    parent_brand: mp.parentBrandName,
    type: mp.type,
    category: mp.category,
    size: mp.size,
    upc: mp.upcs && mp.upcs.length > 0 ? mp.upcs[0] : '',
    existing: [],
    matches: [],
  }
}
