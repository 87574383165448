import { APISupply, Supply } from '../../data/supplies/interfaces'
import { useDispatch } from 'react-redux'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import {
  dispatchCreateOrUpdateSuppliesLara,
  dispatchCreateSupplies,
  dispatchDeleteSupplies,
  dispatchDeleteSuppliesLara,
  dispatchUpdateSupplies
} from '../../data/supplies/api'
import { dollarsToCents } from '../../core/money/utils'
import {
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  Text
} from '@chakra-ui/react'
import { MaterialIcon } from '../../mini-lib/icons/MaterialIcon'
import React, { useState } from 'react'
import { dispatchSetLoadingType } from '../../core/loading/api'
import { SavedStateIcon } from '../../mini-lib/icons/SaveStateIcon'
import { DEFAULT_SUPPLY_NAME } from '../../data/supplies/constants'
import {
  reduceSetStepCompleteDrawerState,
  selectChecklistItem,
  selectStepCompleteDrawerState
} from '../../data/start-guide/slice'
import { HELP_DRAWER_STATES } from '../start-guide/common-components/HelpStepCompleteDrawer'
import { useAppSelector } from '../../hooks'
import { dispatchUpdateUserChecklistItem } from "../../data/start-guide/api";
import { CHECKLIST_CODES } from "../../data/start-guide/constants";
import { UseQueryParams } from "../../mini-lib/utils/basic";
import { ReleaseLaraSupplies } from "../../mini-lib/flags/Release";

export const SupplyNameCell = ( props: { supply: Supply; theme: string } ) => {
  const dispatch = useDispatch()
  const { supply, theme } = props
  const {
    user: { token, userId },
    salonId,
  } = UseBaseApiParams()
  const loadingIdentifier = `supply-${supply.id}`
  const releaseLaraSupplies = ReleaseLaraSupplies()
  const deleteSupply = ( supply: Supply ) => {
    if (releaseLaraSupplies) {
      dispatch(
        dispatchDeleteSuppliesLara({
          token: token,
          salonId,
          models: [supply],
        }),
      )
    } else {
      dispatch(
        dispatchDeleteSupplies({
          token: token,
          user_id: userId,
          salon_id: salonId,
          models: [supply],
        }),
      )
    }
  }
  const updateSupply = ( supply: Supply, updatedName: string ) => {
    const updatedSupply: APISupply = { id: supply.id, name: updatedName, price: dollarsToCents(supply.priceDollars) }
    if (updatedName !== supply.name) {
      if (releaseLaraSupplies) {
        dispatch(
          dispatchCreateOrUpdateSuppliesLara({
            token,
            userId,
            salonId,
            models: [updatedSupply],
            loadingId: loadingIdentifier,
          }),
        )
      } else {
        dispatch(
          dispatchUpdateSupplies({
            token,
            userId,
            salonId,
            models: [updatedSupply],
            loadingId: loadingIdentifier,
          })
        )
      }
    }
  }
  return (
    <Flex
      pl="12px"
      justify="space-between"
      align="center"
      h="100%"
      borderColor={`brand.${theme}.500`}
      borderLeftColor={`brand.${theme}.500`}
      bg={`brand.${theme}.50`}
      color="black"
      width="100%"
      borderLeftWidth="9px"
    >
      <Tooltip label={supply.name}>
        <Editable
          defaultValue={supply.name}
          onSubmit={( updatedName ) => updateSupply(supply, updatedName)}
          isTruncated={true}
          w="100%"
        >
          <EditablePreview cursor="pointer" w="100%"/>
          <EditableInput w="100%"/>
        </Editable>
      </Tooltip>
      <Flex>
        <SupplyMenu theme={theme} supply={supply} deleteSupply={deleteSupply}/>
      </Flex>
    </Flex>
  )
}

export const SupplyMenu = ( props: { theme: string; supply: Supply; deleteSupply: ( Supply ) => void } ) => {
  const { theme, supply, deleteSupply } = props
  return (
    <Menu>
      <MenuButton
        colorScheme={`brand.${theme}`}
        as={IconButton}
        aria-label="Options"
        icon={<MaterialIcon name="more_vert"/>}
        variant="ghost"
      />
      <MenuList>
        <MenuItem
          color="danger"
          onClick={() => {
            deleteSupply(supply)
          }}
          icon={<MaterialIcon name="delete"/>}
        >
          Delete Addon
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

export const SupplyPriceCell = ( props: { supply: Supply } ) => {
  const { supply } = props
  const dispatch = useDispatch()
  const { salonId, user } = UseBaseApiParams()
  const loadingIdentifier = `supply-${supply.id}`
  const [error, setError] = useState('')
  const inputValue: string = supply ? `$${supply?.priceDollars.toFixed(2)}` : ''
  const stepCompleteDrawerState = useAppSelector(selectStepCompleteDrawerState)
  const supplyChecklistItem = useAppSelector(( state ) => selectChecklistItem(state, CHECKLIST_CODES.addAddons))
  const queryParams: any = UseQueryParams()
  const isGuide = !!queryParams.get('guide')
  const releaseLaraSupplies = ReleaseLaraSupplies()

  const createOrUpdateItem = ( updatedPrice: string | any ) => {
    const normalizedPrice = updatedPrice.replace('$', '')
    if (normalizedPrice === '' || isNaN(normalizedPrice) || normalizedPrice < 0) {
      setError('invalid price')
    } else {
      setError('')
      if (normalizedPrice !== supply.priceDollars.toString()) {
        dispatch(dispatchSetLoadingType({ name: loadingIdentifier, state: true }))
        const updatedSupply: APISupply = { id: supply.id, name: supply.name, price: dollarsToCents(normalizedPrice) }
        if (releaseLaraSupplies) {
          dispatch(
            dispatchCreateOrUpdateSuppliesLara({
              token: user.token,
              userId: user.userId,
              salonId,
              models: [updatedSupply],
              loadingId: loadingIdentifier,
            }),
          )
        } else {
          dispatch(
            dispatchUpdateSupplies({
              token: user.token,
              userId: user.userId,
              salonId,
              models: [updatedSupply],
              loadingId: loadingIdentifier,
            })
          )
        }
      }
      if (!supplyChecklistItem.completed && isGuide) {
        dispatch(dispatchUpdateUserChecklistItem({ token: user.token, checklistItemCode: CHECKLIST_CODES.addAddons }))
      }
      if (stepCompleteDrawerState !== HELP_DRAWER_STATES.button) {
        dispatch(reduceSetStepCompleteDrawerState(HELP_DRAWER_STATES.drawer))
      }
    }
  }
  return (
    <Flex
      p={inputValue === '' ? '' : '0 12px'}
      justify="space-between"
      align="center"
      h="100%"
      borderColor="shades.neutral.200"
      borderWidth="1px"
      borderRadius="8px"
    >
      <SavedStateIcon loadingName={loadingIdentifier}/>
      <Flex align="center">
        <Editable
          key={inputValue}
          textAlign="end"
          defaultValue={inputValue}
          placeholder={inputValue === '' ? 'add price' : ''}
          onSubmit={( updatedPrice ) => createOrUpdateItem(updatedPrice)}
          w="100%"
        >
          <EditablePreview
            cursor="pointer"
            color={inputValue ? 'inherit' : 'shades.neutral.500'}
            minW="50px"
            w="100%"
          />
          <EditableInput w="100%" minW="50px"/>
        </Editable>
        <Text fontSize='14' fontWeight="bold">/unit</Text>
        {error && (
          <Popover>
            <PopoverTrigger>
              <IconButton
                ml="4px"
                color="danger"
                aria-label="error"
                variant="ghost"
                size="xs"
                icon={<MaterialIcon size="16px" name="error_outline"/>}
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow/>
              <PopoverCloseButton/>
              <PopoverBody>Please enter a number</PopoverBody>
            </PopoverContent>
          </Popover>
        )}
        {!error && inputValue === '' && (
          <Popover>
            <PopoverTrigger>
              <IconButton
                m="0 4px"
                color="danger"
                aria-label="error"
                variant="ghost"
                size="xs"
                icon={<MaterialIcon size="16px" color="shades.neutral.400" name="visibility_off"/>}
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow/>
              <PopoverCloseButton/>
              <PopoverBody>This will not appear in the app</PopoverBody>
            </PopoverContent>
          </Popover>
        )}
      </Flex>
    </Flex>
  )
}

export const SupplyAddCell = ( props: { theme } ) => {
  const { theme } = props
  const dispatch = useDispatch()
  const {
    user: { token, userId },
    salonId,
  } = UseBaseApiParams()
  const releaseLaraSupplies = ReleaseLaraSupplies()
  const createSupply = () => {
    if (releaseLaraSupplies) {
      dispatch(
        dispatchCreateOrUpdateSuppliesLara({
          token,
          userId,
          salonId,
          models: [{ name: DEFAULT_SUPPLY_NAME, price: 0 }],
          loadingId: 'creating-supplies',
        }),
      )
    } else {
      dispatch(
        dispatchCreateSupplies({
          token: token,
          user_id: userId,
          salon_id: salonId,
          models: [{ name: DEFAULT_SUPPLY_NAME, price: 0 }],
        }),
      )
    }
  }

  return (
    <Flex
      onClick={() => createSupply()}
      cursor="pointer"
      align="center"
      minH="40px"
      h="100%"
      borderColor={`brand.${theme}.500`}
      borderWidth="1px"
      borderRadius="8px"
      borderLeftColor={`brand.${theme}.500`}
      borderLeftWidth="9px"
      p="0 12px"
    >
      + add addon
    </Flex>
  )
}
