import {
  LABOR_SERVICE_TYPES,
  LaborItem,
  LaborService,
  LaborServiceOverride,
  LaborTier
} from '../../data/labor-legacy/interfaces'
import { Box, Flex, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import { buildTierServiceKey, buildTierServiceMap, buildUserServiceKey } from '../../data/labor-legacy/utils'
import { BRAND_THEMES } from '../../theme'
import {
  LaborServiceAddCell,
  LaborServiceCell, LaborServiceOverrideCell,
  LaborTierCell,
  LaborTierServiceCell,
} from './LegacyLaborTableCells'
import { UseViewSize } from '../../core/UseViewSize'
import { stringNumberSort } from '../../mini-lib/utils/sorting'
import {
  ALL_LABOR_CATEGORIES,
  DEFAULT_SERVICE_NAME,
  DEFAULT_TIER_NAME, LABOR_PAGE_TYPES,
} from '../../data/labor-legacy/constants'
import { TableCell } from '../../mini-lib/table/Table'
import { keys } from 'lodash'
import { COLORS } from '../../mini-lib/theme/colors'
import { MaterialIcon } from '../../mini-lib/icons/MaterialIcon'
import { useAppSelector } from "../../hooks";
import {
  selectLaborPageType,
  selectLaborServicesByCategory, selectLaborTierList,
  selectSelectedLaborCategory
} from "../../data/labor-legacy/slice";
import { SalonUser } from "../../data/salon-user/interfaces";
import { selectSalonUsers } from "../../data/salon-user/slice";
import {Banner, SelectInput} from "../../mini-lib";
import { Gap } from "../../mini-lib/gap/Gap";
import {Loading} from "../../mini-lib/loading/Loading";
import {useDispatch} from "react-redux";
import {APISalonUserTierUpsert} from "../../data/user/interfaces";
import {dispatchUpdateSalonUserTiers} from "../../data/user/api";
import {UseBaseApiParams} from "../../core/UseBaseApiParams";

export const LegacyLaborTable = (props: {
  laborServiceOverridesByUserServiceId: {[key: string]: LaborServiceOverride} | null
  laborItems: LaborItem[]
  laborServices: LaborService[]
  laborTiers: LaborTier[]
}) => {
  const { laborServiceOverridesByUserServiceId, laborItems, laborTiers } = props

  const laborServicesByCategory = useAppSelector(selectLaborServicesByCategory)

  // this builds a map by tier-service id (they should be unique)
  // this is much more performant than trying to find an item by tier.id and service.id later
  // todo: consider extracting this to a selector or memoize it
  const laborItemsByTierService: { [key: string]: LaborItem } = buildTierServiceMap(laborItems)
  const selectedLaborCategory = useAppSelector(selectSelectedLaborCategory)
  const pageType = useAppSelector(selectLaborPageType)

  return (
    <Box>
       {pageType === LABOR_PAGE_TYPES.overrides && (
        <Banner iconName='warning' content='Individual pricing will take precedence over the regular service pricing'/>
      )}

      <Gap/>

      {keys(laborServicesByCategory).map((category, categoryIndex) => {
        const servicesForCategory = laborServicesByCategory ? laborServicesByCategory[category] : []
        const showCategory = selectedLaborCategory === category || selectedLaborCategory === ALL_LABOR_CATEGORIES
        return (
          <Box key={category}>
            {showCategory && (
              <CategoryBox
                key={category}
                categoryName={category}
                laborTiers={laborTiers}
                laborServices={servicesForCategory}
                laborItemsByTierService={laborItemsByTierService}
                laborServiceOverridesByUserServiceId={laborServiceOverridesByUserServiceId}
                categoryIndex={categoryIndex}
              />
            )}
          </Box>
        )
      })}
    </Box>
  )
}

export const CategoryBox = (props: {
  categoryName: string
  laborServices: LaborService[]
  laborServiceOverridesByUserServiceId: {[key: string]: LaborServiceOverride} | null
  laborTiers: LaborTier[]
  laborItemsByTierService: { [key: string]: LaborItem }
  categoryIndex: number
}) => {
  const { categoryName, laborServiceOverridesByUserServiceId, laborItemsByTierService, laborServices, laborTiers, categoryIndex } = props
  const { isMobile } = UseViewSize()
  const [showCategoryContent, setShowCategoryContent] = useState(true)
  const salonUsers = useAppSelector(selectSalonUsers)
  const pageType = useAppSelector(selectLaborPageType)
  return (
    <Box key={categoryName}>

      <CategoryHeader
        showCategoryContent={showCategoryContent}
        setShowCategoryContent={setShowCategoryContent}
        categoryName={categoryName}
        numServices={laborServices.length}
      />
      <Flex overflow="scroll">
        {showCategoryContent && (
          <>
            {/* Labor Services Column */}
            <LaborServicesCol categoryName={categoryName} laborServices={laborServices} laborTiers={laborTiers} categoryIndex={categoryIndex}/>

            {/* Tiers */}
            <Flex ml={isMobile ? '12px' : ''}>
              {pageType === LABOR_PAGE_TYPES.list && laborTiers && stringNumberSort(laborTiers, 'name', DEFAULT_TIER_NAME).map((tier, index) => {
                // individual tier column component
                return (
                  <LaborTierCol
                    key={tier.id}
                    tier={tier}
                    laborServices={laborServices}
                    laborItemsByTierService={laborItemsByTierService}
                    tierIndex={index}
                    showTierHeader={laborTiers.length > 1}
                    categoryIndex={categoryIndex}
                  />
                )
              })}

              {pageType === LABOR_PAGE_TYPES.overrides && laborServiceOverridesByUserServiceId && salonUsers?.map((salonUser, index) => {
                // individual tier column component
                return (
                  <LaborSalonUserCol
                    key={salonUser.id}
                    laborServiceOverridesByUserServiceId={laborServiceOverridesByUserServiceId}
                    salonUser={salonUser}
                    laborServices={laborServices}
                    laborItemsByTierService={laborItemsByTierService}
                    tierIndex={index}
                    categoryIndex={categoryIndex}
                  />
                )
              })}
            </Flex>
          </>
        )}
      </Flex>
    </Box>
  )
}
export const CategoryHeader = (props: {
  categoryName: string
  numServices: number
  showCategoryContent: boolean
  setShowCategoryContent: (show: boolean) => void
}) => {
  const { categoryName, numServices, showCategoryContent, setShowCategoryContent } = props
  return (
    <Box
      borderRadius="8px"
      p="12px"
      w="100%"
      bg={COLORS.skylight_50}
      cursor="pointer"
      onClick={() => setShowCategoryContent(!showCategoryContent)}
    >
      <Flex align="center" gridGap="12px">
        <MaterialIcon name={showCategoryContent ? 'expand_less' : 'expand_more'} />
        <Box>
          <Text variant="default/regular/title3">{categoryName}</Text>
          <Text color={COLORS.text_secondary}>{numServices} services</Text>
        </Box>
      </Flex>
    </Box>
  )
}
export const LaborServicesCol = (props: {
  categoryName: string;
  laborServices: LaborService[],
  laborTiers: LaborTier[]
  categoryIndex: number
}) => {
  const { laborServices, categoryName, laborTiers, categoryIndex } = props
  const { isMobile } = UseViewSize()
  const width = isMobile
    ? '200px'
    : laborTiers.length > 1
      ? '250px'
      : '500px'
  return (
    <Box m={isMobile ? '' : '12px'}>
      <TableCell width={width} />
      {laborServices && (
        <>
          {stringNumberSort(laborServices, 'name', DEFAULT_SERVICE_NAME).map((service, index) => {
            return (
              <TableCell key={service.id} width={width}>
                <LaborServiceCell service={service} theme={BRAND_THEMES.skylight} serviceIndex={index} categoryIndex={categoryIndex}/>
              </TableCell>
            )
          })}
          <TableCell width={width}>
            <LaborServiceAddCell categoryName={categoryName} serviceType={LABOR_SERVICE_TYPES.hourly} theme={BRAND_THEMES.skylight} index={categoryIndex} />
          </TableCell>
        </>
      )}
      {!laborServices && <TableCell width={width} />}

      <TableCell width={width} />
    </Box>
  )
}

export const LaborTierCol = (props: {
  tier: LaborTier
  laborServices: LaborService[]
  laborItemsByTierService: { [key: string]: LaborItem }
  showTierHeader: boolean
  tierIndex: number
  categoryIndex: number
}) => {
  const { tier, laborServices, laborItemsByTierService, tierIndex, categoryIndex, showTierHeader } = props
  const { isMobile } = UseViewSize()
  return (
    <Box key={tier.id} m={isMobile ? '0 12px 0 0' : '12px'}>
      <TableCell>
        {showTierHeader && <LaborTierCell tier={tier} tierIndex={tierIndex} categoryIndex={categoryIndex}/>}
      </TableCell>

      {/*pricing for hourly items by tier*/}
      {laborServices &&
        laborServices.map((service, index) => {
          return (
            <TableCell key={buildTierServiceKey(tier.id, service.id)}>
              <LaborTierServiceCell
                service={service}
                tier={tier}
                laborItemsByTierService={laborItemsByTierService}
                type={service.type}
                serviceIndex={index}
                tierIndex={tierIndex}
                categoryIndex={categoryIndex}
              />
            </TableCell>
          )
        })}
    </Box>
  )
}

export const LaborSalonUserCol = (props: {
  salonUser: SalonUser
  laborServiceOverridesByUserServiceId: { [key: string]: LaborServiceOverride }
  laborServices: LaborService[]
  laborItemsByTierService: { [key: string]: LaborItem }
  tierIndex: number
  categoryIndex: number
}) => {
  const dispatch = useDispatch()
  const { isMobile } = UseViewSize()
  const {user, salonId} = UseBaseApiParams()
  const { laborServiceOverridesByUserServiceId, salonUser, laborServices, laborItemsByTierService, tierIndex, categoryIndex } = props
  const tier: LaborTier = {id: salonUser.tierId || -1, name: salonUser.tierName || ''}
  const hasTier = !!salonUser.tierId
  const onTierSelected = (tierId: number) => {
    const model = {user_id: salonUser.userId, tier_id: tierId}
    if (salonUser.userTierId) {
      model['id'] = salonUser.userTierId
    }

    const models: APISalonUserTierUpsert[] = [model]
    dispatch(dispatchUpdateSalonUserTiers({ token: user.token, userId: user.userId, salonId, models: models }))
  }
  return (
    <Box key={tier.id} m={isMobile ? '0 12px 0 0' : '12px'}>
      <TableCell>{salonUser.fullName}</TableCell>

      {!hasTier && (
        <>
          <SelectLaborTier onChange={onTierSelected}/>
        </>
      )}
      {/*pricing for hourly items by salonUser*/}
      {hasTier && laborServices && laborServices.map((service, index) => {
          const userServiceKey = buildUserServiceKey(salonUser.id, service.id)
          const laborServiceOverride = laborServiceOverridesByUserServiceId[userServiceKey]
          return (
            <TableCell key={buildTierServiceKey(tier.id, service.id)}>
              <LaborServiceOverrideCell
                laborServiceOverridesByUserServiceId={laborServiceOverridesByUserServiceId}
                salonUser={salonUser}
                service={service}
                tier={tier}
                laborItemsByTierService={laborItemsByTierService}
                type={service.type}
                serviceIndex={index}
                tierIndex={tierIndex}
                categoryIndex={categoryIndex}
                laborServiceOverride={laborServiceOverride}
              />
            </TableCell>
          )
        })}
    </Box>
  )
}

export const SelectLaborTier = (props: { onChange: (tierId: number) => void }) => {
  const {onChange} = props
  const [tier, setTier] = useState(null)
  const allTiers = useAppSelector(selectLaborTierList);
  const options = allTiers ? allTiers.map(tier => {
    return {label: tier.name, value: tier.id}
  }) : []

  return (
    <>
      {!allTiers && <Loading/>}
      {allTiers && (
        <SelectInput
          variant='material'
          border='normal'
          label='select a level'
          options={options}
          value={tier}
          errorText={''}
          onBlur={() => {}}
          onChange={(tierId) => {setTier(tierId); onChange(tierId)}}
          allowFocus={true}
          isClearable={false}
        />
      )}
    </>
  )
}
