import { useEffect, useState } from 'react'

var unsafeDocument: any = document
var unsafeWindow: any = window

export const UseDeviceInfo = () => {
  const [deviceInfo, setDeviceInfo] = useState<any>({userAgentDevice: null, userAgentBrowser: null})

  useEffect(() => {
    const userAgentBrowser = getBrowserFromUserAgent(navigator?.userAgent || '')
    const userAgentDevice = getDeviceFromUserAgent(navigator?.userAgent || '')
    setDeviceInfo({userAgentDevice, userAgentBrowser})
  }, [])

  // ref - check useragent https://stackoverflow.com/a/26358856
  const getBrowserFromUserAgent = (userAgent: string): string | null => {
    if ((userAgent.indexOf('Opera') || userAgent.indexOf('OPR')) !== -1) {
      return 'opera'
    } else if (userAgent.indexOf('Edg') !== -1) {
      return 'edge'
    } else if (userAgent.indexOf('Chrome') !== -1) {
      return 'chrome'
    } else if (userAgent.indexOf('Safari') !== -1) {
      return 'safari'
    } else if (userAgent.indexOf('Firefox') !== -1) {
      return 'firefox'
    } else if (userAgent.indexOf('MSIE') !== -1 || (unsafeDocument && unsafeDocument.documentMode)) {
      //IF IE > 10
      return 'IE'
    }
    return null
  }

  const getDeviceFromUserAgent = (userAgent: any): string | null => {
    if (/android/i.test(userAgent)) {
      return 'android'
    } else if (/iPhone|iPod/i.test(userAgent) && !unsafeWindow.MSStream) {
      return 'ios'
    } else if (/iPad/i.test(userAgent) && !unsafeWindow.MSStream) {
      return 'ios'
    } else {
      return 'desktop'
    }
  }

  const deviceInfoString = JSON.stringify(deviceInfo, null, 2)
  return deviceInfoString
}
