import React from 'react'
import AppRouter from './AppRouter'
import { Box } from '@chakra-ui/react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ColorForMode } from './theme'
import { InitializeLDSalon } from './integrations/launchDarkly'
import { InitializeGrin } from './integrations/grin/service'
import { InitializeIntercomUser } from "./integrations/gtag";
import { InitializeMixpanel } from './integrations/mixpanel/mixpanel'
import { InitializeSmartlook } from "./integrations/smartlook/service";

function App() {
  // we initialize ld and grin inside the app not outside like sentry because we need user and salon info to send
  InitializeLDSalon()
  InitializeGrin()
  InitializeIntercomUser()
  InitializeMixpanel()
  InitializeSmartlook()
  return (
    <Box minHeight="100vh" background='white' color='black'>
      {/* note: only have one toast container in the dom*/}
      <ToastContainer toastStyle={{ backgroundColor: ColorForMode('secondary-bg') }} autoClose={3000} />
      <AppRouter />
    </Box>
  )
}

export default App
