// admin lara color apis
//
//

import { buildLaraConfig } from '../../mini-lib/lara/lara-utils'
import { APIBulkUpsertMasterColorLara, APIGetBulkMatches, BulkMatches } from './interfaces'
import { Dispatch } from '@reduxjs/toolkit'
import { reduceSetLoadingState } from '../../core/loading/slice'
import {reduceAdminBulkMatchMasterColorsLara, reduceCreatedMasterColors, reduceUploadErrors} from './slice'
import axios from 'axios'
import { mapApiBulkMatchesToBulkMatches } from './mappers'
import { mapAPIMasterProductsLaraToMasterProducts } from '../master-products/mappers'
import { MasterProduct } from '../master-products/interfaces'
import {GetServerBaseUrl} from "../../env";

export const apiAdminBulkMatchMasterColorsLara = (params: {
  token: string
  body: APIGetBulkMatches
}): Promise<BulkMatches> => {
  const { token, body } = params
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/admin/mastercolors/bulk-match`
  return axios
    .post(url, body, config)
    .then((response: { data }) => {
      return mapApiBulkMatchesToBulkMatches(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const dispatchAdminBulkMatchMasterColorsLara = (params: {
  token: string
  body: APIGetBulkMatches
  loadingName: string
}) => {
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: params.loadingName, state: true }))
    return apiAdminBulkMatchMasterColorsLara(params)
      .then((resp) => {
        dispatch(reduceAdminBulkMatchMasterColorsLara(resp))
      })
      .finally(() => {
        dispatch(reduceSetLoadingState({ name: params.loadingName, state: false }))
      })
  }
}

export const apiAdminBulkUpsertMasterColorsLara = (params: {
  token: string
  models: APIBulkUpsertMasterColorLara[]
}): Promise<MasterProduct[]> => {
  const { token, models } = params
  const config = buildLaraConfig({ token })
  const body = {
    master_products: models,
  }
  const url = `${GetServerBaseUrl('v3', 'lara')}/admin/mastercolors/bulk-upsert`
  return axios
    .post(url, body, config)
    .then((response: { data }) => {
      return mapAPIMasterProductsLaraToMasterProducts(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const dispatchAdminBulkUpsertMasterColorsLara = (params: {
  token: string
  models: APIBulkUpsertMasterColorLara[]
  loadingName: string
}) => {
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: params.loadingName, state: true }))
    return apiAdminBulkUpsertMasterColorsLara(params)
      .then((resp) => {
        dispatch(reduceCreatedMasterColors(resp))
      })
      .catch((resp) => {
        const errors = resp?.response?.data?.errors
        dispatch(reduceUploadErrors(errors))
      })
      .finally(() => {
        dispatch(reduceSetLoadingState({ name: params.loadingName, state: false }))
      })
  }
}
