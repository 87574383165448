import {CategoryAndColor, Labor} from "../../../data/labor/interfaces";
import {useDispatch} from "react-redux";
import {UseViewSize} from "../../../core/UseViewSize";
import {reduceSelectedLabor} from "../../../data/labor/slice";
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Text
} from "@chakra-ui/react";
import {DisableAutofocus} from "../../../mini-lib/disable-autofocus/DisableAutofocus";
import React from "react";
import {LaborForm} from "./LaborForm";

export const LaborSheet = (props: {
  laborTemplate: Labor | null
  labors: Labor[] | null
  categoryAndColor: CategoryAndColor
  show: boolean
  setShow: (show: boolean) => void
}) => {
  const dispatch = useDispatch()
  const { labors, laborTemplate, categoryAndColor, show, setShow } = props
  const { isMobile } = UseViewSize()
  const onClose = () => {
    setShow(false)
    dispatch(reduceSelectedLabor(null))
  }
  return (
    <>
      {show && (
        <Drawer size={isMobile ? 'xs' : 'md'} isOpen={show} placement="right" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader>
              <Flex justify="space-between" align="center">
                <Text variant="title3">{laborTemplate ? 'Update Service' : 'Add Service'}</Text>
                <Flex align="center" gridGap="12px">
                  <DrawerCloseButton />
                </Flex>
              </Flex>
            </DrawerHeader>
            <DrawerBody>
              <>
                <DisableAutofocus />
                <LaborForm
                  formType={!!laborTemplate ? 'update' : 'create'}
                  laborTemplate={laborTemplate}
                  labors={labors}
                  categoryAndColor={categoryAndColor}
                  onClose={onClose}
                />
              </>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </>
  )
}