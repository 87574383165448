// @flow
import {
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Text,
} from '@chakra-ui/react'
import { MaterialIcon } from '../../mini-lib/icons/MaterialIcon';
import { COLORS } from '../../mini-lib/theme/colors';
import { getLocalStorageItem, setLocalStorageItem } from '../../core/localStorage';
import { useEffect, useState } from 'react';


export const SalonDefaultUnit = (props: { show: boolean; setShow: (show: boolean) => void }) => {
  const { show, setShow } = props
  const [currentUnit, setCurrentUnit] = useState<null | string>(null)

  useEffect(() => {
    if (getLocalStorageItem('unit')) {
      setCurrentUnit(getLocalStorageItem('unit'));
    }
  }, [])

  useEffect(() => {
    if (currentUnit) {
      setLocalStorageItem('unit', currentUnit);
    }
  }, [currentUnit])

  return (
    <Drawer placement="right" onClose={() => setShow(false)} isOpen={show}>
      <DrawerOverlay />
      <DrawerContent>
        <>
          <DrawerHeader>
            <Text variant="title2">Default Unit</Text>
          </DrawerHeader>
          <DrawerBody>
            <Flex
              direction="column"
              p="0 12px"
              justify="space-around"
              align="center"
              h="96px"
              bg="shades.neutral.200"
              color="black"
              width="100%"
              borderRadius="8px"
            >
              <Flex w='100%' direction="row" justify="space-between" onClick={() => setCurrentUnit('g')}>
                <Text isTruncated={true}>gram</Text>
                {currentUnit === 'g' && <MaterialIcon cursor="pointer" size="24px" colorhex={COLORS.text_secondary} name="check" />}
              </Flex>
              <Divider borderColor="black" />
              <Flex w='100%' direction="row" justify="space-between" onClick={() => setCurrentUnit('oz')}>
                <Text isTruncated={true}>ounces</Text>
                {currentUnit === 'oz' && <MaterialIcon cursor="pointer" size="24px" colorhex={COLORS.text_secondary} name="check" />}
              </Flex>
            </Flex>
          </DrawerBody>
        </>
      </DrawerContent>
    </Drawer>
  )
}
