import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { SignupFormLayout } from './SignupFormLayout'
import { Gap } from '../../../mini-lib/gap/Gap'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { APILaraPatchSalon } from '../../../data/salon/interfaces'
import { dispatchLaraPatchSalon } from '../../../data/salon/api'
import { Redirect } from 'react-router-dom'
import { ROUTES } from '../../../appRoutes'
import { RadioGroupInput } from '../../../mini-lib'
import { RadioOption } from '../../../mini-lib/form/RadioGroupInput'

export const SignupSalonPartsAndLaborPage = () => {
  return (
    <SignupFormLayout stepNumber={3} totalSteps={4}>
      <Flex justify="center" align="center" direction="column">
        <Gap />
        <Text variant="title2-desktop-bold">Parts and Labor Method</Text>
        <Gap s="6px" />
        <Text variant="callout-desktop-regular">How familiar are you?</Text>
        <Gap s="32px" />
        <SalonPartsAndLaborForm />
      </Flex>
    </SignupFormLayout>
  )
}

const checkboxSelectOptions: RadioOption[] = [
  {label: <Text><Text as='span' fontWeight='bold'>Very familiar</Text> - want to adopt the method</Text>, value: 'very familiar'},
  {label: <Text><Text as='span' fontWeight='bold'>Basic understanding</Text> - want to learn more</Text>, value: 'familiar'},
  {label: <Text><Text as='span' fontWeight='bold'>Not familiar</Text> - want to learn more</Text>, value: 'not familiar'},
  ]

const SalonPartsAndLaborForm = () => {
  const dispatch = useDispatch()
  const { user } = UseBaseApiParams()

  const [selectedOption, setSelectedOption] = useState<RadioOption | null>(null)
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState<string>('')

  const submitClicked = () => {
    if (!selectedOption) {
      setError('Please select an option to continue!')
      return
    }
    const body: APILaraPatchSalon = {
      signup_answer_parts_and_labor: selectedOption.value,
    }

    dispatch(
      dispatchLaraPatchSalon({
        token: user.token,
        userId: user.userId,
        currentSalonContext: user.currentSalonContext,
        salonId: user.currentSalonContext?.salonId || -1,
        request: body,
        syncToPipedrive: false,
      }),
    )
    setSubmitted(true)
  }
  if (submitted) {
    return <Redirect to={ROUTES.signupV2Colors} />
  }
  return (
    <Box w="100%">
      <Box p="24px">
        <RadioGroupInput
          onChange={(option) => setSelectedOption(option)}
          options={checkboxSelectOptions}
          value={selectedOption}
          errorText={error}
          theme="lavender"
        />
      </Box>

      <Box p="0 12px">
        <Button variant="round-large" colorScheme="brand.midnight" w="100%" onClick={() => submitClicked()}>
          Continue
        </Button>
      </Box>
      <Gap/>
    </Box>
  )
}
