import {APILaborUpsert, Labor} from './interfaces'
import { convertHoursMinutesToSeconds } from '../../mini-lib/form/TimeInput'
import {filter, keyBy} from "lodash";
import {COLORS} from "../../mini-lib/theme/colors";
import {UPSERT_LABORS} from "./constants";

export const buildLoadingLaborId = (id: number) => {
  return `${UPSERT_LABORS}-${id}`
}

export const groupLaborsByCategory = (items: Labor[] | null): { [category: string]: Labor[] } | null => {
  if (!items) {
    return null
  }

  const map: { [category: string]: Labor[] } = {}

  items.forEach((item) => {
    const category = item.category
    // initialize array if category doesn't exist
    if (!map[category]) {
      map[category] = []
    }
    map[category].push(item)
  })

  return map
}

// note: extracted this logic into a pure function because it has extra business logic in it
// this should have some regression tests as it would be easy to accidentally break
//
export const getLaborModelsForUpsertAndDelete = (params: {
  salonId: number

  formType: 'create' | 'update' | string
  existingLabors: Labor[] | null

  name: string
  colorhex: string
  category: string

  timeHours: number | null
  timeMinutes: number | null

  supplyIds: number[]
  userIds: any | number[]
}): { upsertModels: APILaborUpsert[]; deleteModels: Labor[] } => {
  const {salonId, formType, existingLabors, name, colorhex, category, timeHours, timeMinutes, supplyIds, userIds} = params
  const seconds = convertHoursMinutesToSeconds({ hours: timeHours, minutes: timeMinutes })
  const existingLaborsForSelectedCategoryByUserId = keyBy(existingLabors, 'userId')

  const modelsToUpsert: APILaborUpsert[] = []


  // this handles the case where they have a labor template but have removed all users from it
  //
  // if there are userIds remove the template
  //
  const normalizedUserIds = userIds.filter(id => id !== null)
  //
  // if there are no user ids ensure there is a template
  //
  if (normalizedUserIds.length === 0) {
    normalizedUserIds.push(null)
  }


  normalizedUserIds.forEach((userId: null | number) => {
    let existingLabor: Labor | null = null
    if (userId && formType === 'update' && existingLaborsForSelectedCategoryByUserId[userId]) {
      existingLabor = existingLaborsForSelectedCategoryByUserId[userId] || null

    // handle the null userId case for when they want to create a template
    //
    } else if (!userId && existingLabors && existingLabors.length === 1) {
      existingLabor = existingLabors[0]
    }
    const model = {
      id: existingLabor ? existingLabor.id : null,
      salon_id: salonId,
      user_id: userId,
      name: name,
      colorhex: colorhex || COLORS.skylight_500,
      category: category,
      duration_seconds: seconds,
      supply_ids: supplyIds,
    }
    modelsToUpsert.push(model)
  })

  const laborsToDelete = existingLabors
    ? filter(existingLabors, (labor) => {
        // delete if the userId is no longer included in the labor templates
        //
        return !userIds.includes(labor.userId) ||
          (labor.userId === null && userIds.length > 1) // there will always be at least one null user id
      }
    )
    : []
  return { upsertModels: modelsToUpsert, deleteModels: laborsToDelete }
}

export const buildDefaultLabors = (params: {salonId: number}): APILaborUpsert[] => {
  const {salonId} = params
  return [
    { name: 'Single Process Color',  category: 'Full Color Services', id: null, user_id: null, salon_id: salonId, colorhex: COLORS.midnight_500,  duration_seconds: 5400,  supply_ids: []},
    { name: 'Style',  category: 'Hair Cuts + Styling', id: null, user_id: null, salon_id: salonId, colorhex: COLORS.lavender_500,  duration_seconds: 1800,  supply_ids: []},
    { name: 'Balayage',  category: 'Full Color Services', id: null, user_id: null, salon_id: salonId, colorhex: COLORS.midnight_500,  duration_seconds: 10800,  supply_ids: []},
    { name: 'Full Highlights',  category: 'Full Color Services', id: null, user_id: null, salon_id: salonId, colorhex: COLORS.midnight_500,  duration_seconds: 9000,  supply_ids: []},
    { name: 'Color Correction',  category: 'Full Color Services', id: null, user_id: null, salon_id: salonId, colorhex: COLORS.midnight_500,  duration_seconds: 14400,  supply_ids: []},
    { name: 'Single Process Color',  category: 'Full Color Services', id: null, user_id: null, salon_id: salonId, colorhex: COLORS.midnight_500,  duration_seconds: 5400,  supply_ids: []},
  ]
}