// todo: deprecated
export interface APICreateSalon {
  user_id: number
  salon_name: string
  salon_phone?: string
  instagram_handle?: string
  booking_software?: string
  timezone?: string
}

// todo: deprecated
export interface APIUpdateSalon {
  name?: string
  default_markup_percentage?: number
  default_extension_markup_percentage?: number
  salon_phone?: string
  instagram_handle?: string
  booking_software?: string
  timezone?: string | null
}

// todo: deprecated
export interface APISalon {
  id: number
  name: string
  phone: string
  city: string
  country: string
  province: string
  postal_code: string
  address_line_1: string
  date_added: string
  notes: string
  deleted: boolean
  session_count?: number
  customer_id?: number
  default_markup_percentage: number
  default_extension_markup_percentage: number
  instagram_handle?: string
  booking_software?: string
  timezone: string
}

export interface APILaraPatchSalon {
  name?: string
  timezone?: string | null
  photo_url?: string | null
  phone?: string
  default_extension_markup_percentage?: number
  default_markup_percentage?: number
  trial_plan_id?: string | null
  trial_salon_description?: string
  trial_start_date?: string | null
  trial_expiry_date?: string | null
  signup_answer_num_stylists?: string | null
  signup_answer_problems?: string | null
  signup_answer_parts_and_labor?: string | null
  signup_answer_device_info?: string | null
}

export interface APILaraSalon {
  id: number
  name: string
  phone: string
  address: string
  notes: string
  city: string
  country: string
  mailing_address: string
  postal_code: string
  province: string
  timezone: string
  default_markup_percentage: number
  default_extension_markup_percentage: number
  photo_url: string
  role?: {
    id: number
    role: string
    permissions: string[]
  }
  clients_count?: number
  products_count?: number
  inventory_completed_count?: number
  target_set_count?: number
  labor_services_count?: number
  on_hand_set_count?: number
  extension_count?: number
  color_count?: number
  color_priced_count?: number
  extension_priced_count?: number
  supplies_count?: number
  stylists_count?: number
  trial_start_date?: string | null
  trial_expiry_date?: string | null
  trial_plan_id?: string | null
  trial_salon_description?: string | null
}

export interface Salon {
  id: number
  name: string
  phone: string
  city: string
  country: string
  province: string
  postalCode: string
  addressLine1: string
  notes: string
  sessionCount?: number
  customerId?: number
  defaultMarkupPercentage: number
  defaultExtensionMarkupPercentage: number
  instagramHandle?: string
  bookingSoftware?: string
  timezone: string
  photoUrl: string
  role?: {
    id: number
    role: string
    permissions: string[]
  }
  clientsCount: number
  productsCount: number
  inventoryCompletedCount: number
  onHandSetCount: number
  targetSetCount: number
  laborServicesCount: number
  extensionsCount: number
  colorsCount: number
  colorsPricedCount: number
  extensionsPricedCount: number
  addonsCount: number
  teamCount: number
  trialStartDate: Date | null
  trialExpiryDate: Date | null
  trialPlanId: string | null
  trialSalonDescription: string | null
}

export type SalonType = 'salon' | 'independent' | 'multi'

export const SalonTypes = {
  salon: 'salon',
  independent: 'independent', // note this will be deprectated after v2 is launched
  multi: 'multi',
  solo: 'independent',
}

export const SubscriptionTypes = {
  monthly: 'monthly',
  // yearly: 'yearly', // we no longer have yearly
  bundle: 'bundle',
  meetyourstylist: 'meetyourstylist',
}


export interface APICreateSalonLara {
  name: string
  user_id: number
  phone?: string
  photo_url?: string
  timezone?: string
  default_extension_markup_percentage?: number
  default_markup_percentage?: number
  trial_plan_id?: string
  trial_salon_description?: string
  trial_start_date?: string
  trial_expiry_date?: string
  signup_answer_num_stylists?: string | null
  signup_answer_problems?: string | null
  signup_answer_parts_and_labor?: string | null
  signup_answer_device_info?: string | null
}

export interface APIUpdateSalonLara {
  name?: string
  user_id?: number
  phone?: string
  photo_url?: string
  timezone?: string
  default_extension_markup_percentage?: number
  default_markup_percentage?: number
  trial_plan_id?: string | null
  trial_salon_description?: string
  trial_start_date?: string | null
  trial_expiry_date?: string | null
}
