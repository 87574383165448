// references
//
// slice redux docs - https://redux-toolkit.js.org/tutorials/typescript
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {assign, keyBy} from 'lodash'

import { RootState } from '../../store'
import { CSVMasterProduct, MasterProduct } from '../master-products/interfaces'
import { BulkMatches } from './interfaces'

// state
//
interface AdminMasterProductState {
  createdMasterProductsById: { [key: string]: MasterProduct } | null
  csvMasterProducts: { [key: string]: CSVMasterProduct } | null
  createdMasterColors: MasterProduct[] | null
  bulkMatches: BulkMatches | null
  searchText: string
  uploadErrors: any | null
}

const initialState: AdminMasterProductState = {
  createdMasterProductsById: null, // undefined is an accident, null is a choice, this lets us know when something is loading
  csvMasterProducts: null,
  createdMasterColors: null,
  bulkMatches: null,
  searchText: '',
  uploadErrors: null
}

// reducer
//
export const AdminMasterProductSlice = createSlice({
  name: 'adminMasterProducts',
  initialState,
  reducers: {
    reduceCreatedMasterColors: (state, action: PayloadAction<MasterProduct[]>) => {
      if (action.payload && action.payload.length > 0) {
        state.createdMasterProductsById = assign({}, state.createdMasterProductsById, keyBy(action.payload, 'id'))
        state.createdMasterColors = action.payload
      }
    },
    reduceAdminBulkMatchMasterColorsLara: (state, action: PayloadAction<BulkMatches>) => {
      state.bulkMatches = action.payload
    },
    reduceSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload
    },
    reduceUploadErrors: (state, action: PayloadAction<any>) => {
      state.uploadErrors = action.payload
    },
  },
})

// actions
//
export const {
  reduceCreatedMasterColors,
  reduceAdminBulkMatchMasterColorsLara,
  reduceUploadErrors
} =
  AdminMasterProductSlice.actions

export const selectBulkMatches = (state: RootState): BulkMatches | null => {
  return state.adminMasterProducts.bulkMatches
}

export const selectCreatedMasterColors = (state: RootState): MasterProduct[] | null => {
  return state.adminMasterProducts.createdMasterColors
}

export const selectUploadErrors = (state: RootState): any | null => {
  return state.adminMasterProducts.uploadErrors
}

// export
//
export default AdminMasterProductSlice.reducer
