import React from 'react'
import { Box, Flex } from '@chakra-ui/react'
import { InputError } from './InputError'
import { ThemeType } from '../../theme'
import { COLORS } from '../theme/colors'
import { MaterialIcon } from '../icons/MaterialIcon'

export interface RadioOption {
  value: string | any
  label: string | any
}

export const RadioGroupInput = (props: {
  isDisabled?: boolean
  options: RadioOption[]
  value: RadioOption | null
  onChange: (optionValue: string | any) => void
  onBlur?: (optionValue: string) => void // this is used for setTouched on formik forms
  errorText?: string
  variant?: 'material'
  theme?: ThemeType
  w?: string
}) => {
  const { variant = 'material', w = '100%' } = props
  return <Box w={w}>{variant === 'material' && <MaterialRadioGroupInput {...props} />}</Box>
}

export const MaterialRadioGroupInput = (props: {
  isDisabled?: boolean
  options: RadioOption[]
  value: RadioOption | any
  onChange: (optionValue: any) => void
  errorText?: string
  theme?: ThemeType
}) => {
  const {
    options,
    value,
    onChange,
    errorText = '',
    theme = 'basic',
  } = props

  const handleChange = (option: { value: string | string[] } | any) => {

    if (option) {
      onChange(option)
    } else {
      onChange(null)
    }
  }
  const themeColor = theme === 'basic' ? 'black' : COLORS[`${theme}_500`]
  return (
    <Box>
      {options.map((option) => {
        const isSelected = value && value.value === option.value
        return (
          <Flex
            justify='space-between'
            borderRadius={'8px'}
            key={option.value}
            onClick={() => handleChange(option)}
            border={isSelected ? `2px solid ${themeColor}` : `1px solid ${COLORS.shades_neutral_300}`}
            p='12px 24px'
            mb='12px'
            boxSizing='border-box'
            cursor='pointer'
          >
            {option.label}
            {isSelected ? (
              <MaterialIcon name="check_circle" colorhex={themeColor} />
            ) : (
              <MaterialIcon name="radio_button_unchecked" colorhex={COLORS.shades_neutral_300}/>
            )}
          </Flex>
        )
      })}
      <InputError showError={!!errorText} errorText={errorText} />
    </Box>
  )
}
