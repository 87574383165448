import React from 'react'
import { Box, Flex } from '@chakra-ui/react'
import { InputError } from './InputError'
import { ThemeType } from '../../theme'
import { COLORS } from '../theme/colors'
import { MaterialIcon } from '../icons/MaterialIcon'

export interface CheckboxOption {
  value: string | any
  label: string | any
}

export const CheckboxGroupInput = (props: {
  options: CheckboxOption[]
  selectedOptions: CheckboxOption[]
  onChange: (optionValue: CheckboxOption[]) => void
  onBlur?: (optionValue: string) => void // this is used for setTouched on formik forms
  errorText?: string
  variant?: 'material'
  theme?: ThemeType
  border?: 'normal' | 'rounded'
  w?: string
}) => {
  const { variant = 'material', w = '100%' } = props
  return <Box w={w}>{variant === 'material' && <MaterialCheckboxGroupInput {...props} />}</Box>
}

export const MaterialCheckboxGroupInput = (props: {
  isDisabled?: boolean
  w?: string
  options: CheckboxOption[]
  selectedOptions: CheckboxOption[] | any
  onChange: (optionValue: CheckboxOption[]) => void
  errorText?: string
  theme?: ThemeType
  border?: 'normal' | 'rounded'
}) => {
  const {
    options,
    selectedOptions,
    onChange,
    errorText = '',
    theme = 'basic',
    border = '',
  } = props

  const handleChange = (option: CheckboxOption) => {
    const isSelected = selectedOptions.some(v => v.value === option.value)
    let updatedSelectedOptions: CheckboxOption[]

    if (isSelected) {
      // remove option if it's already selected
      updatedSelectedOptions = selectedOptions.filter(o => o.value !== option.value)
    } else {
      // add option if it's not selected
      updatedSelectedOptions = [...selectedOptions, option]
    }
    onChange(updatedSelectedOptions)
  }
  const themeColor = theme === 'basic' ? 'black' : COLORS[`${theme}_500`]
  return (
    <Box>
      {options.map((option) => {
        const selectedValues = selectedOptions ? selectedOptions.map(v => v.value) : [];
        const isSelected = selectedValues.includes(option.value)
        return (
          <Flex
            justify='space-between'
            borderRadius={border === 'rounded' ? '50px' : '8px'}
            key={option.value}
            onClick={() => handleChange(option)}
            border={isSelected ? `2px solid ${themeColor}` : `1px solid ${COLORS.shades_neutral_300}`}
            p='12px 24px'
            mb='12px'
            boxSizing='border-box'
            cursor='pointer'
          >
            {option.label}
            {isSelected ? (
              <MaterialIcon name="check_box" colorhex={themeColor} />
            ) : (
              <MaterialIcon name="check_box_outline_blank" colorhex={COLORS.shades_neutral_300}/>
            )}
          </Flex>
        )
      })}
      <InputError showError={!!errorText} errorText={errorText} />
    </Box>
  )
}
