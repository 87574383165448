import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../hooks'
import {
  reduceEditableLaborCategory,
  reduceSelectedLaborCategory,
  selectLabors,
  selectSelectedLaborCategoryAndColor,
} from '../../../data/labor/slice'
import {
  Box,
  Button,
  Circle,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { COLORS } from '../../../mini-lib/theme/colors'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { MaterialIcon } from '../../../mini-lib/icons/MaterialIcon'
import { dispatchDeleteLaborsForCategory, dispatchUpsertLabors } from '../../../data/labor/api'
import React, { useState } from 'react'
import { orderBy } from 'lodash'
import { selectLoadingState } from '../../../core/loading/slice'
import { LABOR_MENU_UPSERT, LIST_LABORS } from '../../../data/labor/constants'
import { Gap } from '../../../mini-lib/gap/Gap'
import { Loading } from '../../../mini-lib/loading/Loading'
import { APILaborUpsert, CategoryAndColor } from '../../../data/labor/interfaces'
import { SelectInput, TextInput } from '../../../mini-lib'
import { EmptyBox } from '../../../mini-lib/empty/EmptyBox'

export const LaborCategoryList = (props: {
  categoryAndColors: CategoryAndColor[]
  setIsCategoryModalVisible: (bool: boolean) => void
}) => {
  const dispatch = useDispatch()
  const { categoryAndColors, setIsCategoryModalVisible } = props
  const sortedModels = orderBy(
    categoryAndColors,
    [(categoryAndColor) => categoryAndColor.category.toLowerCase()],
    ['asc'],
  )
  const laborsLoading = useAppSelector((state) => selectLoadingState(state, LIST_LABORS))
  const menuCreating = useAppSelector((state) => selectLoadingState(state, LABOR_MENU_UPSERT))

  const onCategoryCreate = () => {
    setIsCategoryModalVisible(true)
    dispatch(reduceEditableLaborCategory({ category: '', colorhex: COLORS.midnight_500 }))
  }

  return (
    <Box w="240px" minW="240px">
      <Text variant="default/regular/title3">Menus</Text>

      <Gap />
      {laborsLoading && <Loading />}
      {sortedModels.map((model, index) => {
        return <LaborCategoryOption key={model.category} categoryAndColor={model} />
      })}
      {!laborsLoading && sortedModels && sortedModels.length === 0 && (
        <Box>
          <EmptyBox title="NO MENUS SET" content="Click 'add menu' below to get started."></EmptyBox>
        </Box>
      )}

      <Gap />

      <Button variant="round" colorScheme="brand.midnight" onClick={onCategoryCreate}>
        {menuCreating ? <Loading /> : 'Add Menu'}
      </Button>
    </Box>
  )
}

const LaborCategoryOption = (props: { categoryAndColor: CategoryAndColor; showMenu?: boolean }) => {
  const { categoryAndColor, showMenu = true } = props
  const dispatch = useDispatch()
  const selectedCategoryAndColor = useAppSelector(selectSelectedLaborCategoryAndColor)
  const isSelected = selectedCategoryAndColor?.category === categoryAndColor.category
  return (
    <Flex
      cursor="pointer"
      align="center"
      justify="space-between"
      h="40px"
      borderRadius="50px"
      p="12px"
      bg={isSelected ? COLORS.skylight_50 : ''}
      color={isSelected ? COLORS.skylight_500 : ''}
    >
      <Text flex="1" onClick={() => dispatch(reduceSelectedLaborCategory(categoryAndColor))}>
        {categoryAndColor.category}
      </Text>
      {showMenu && (
        <Box>
          <LaborCategoryMenu categoryAndColor={categoryAndColor} />
        </Box>
      )}
    </Flex>
  )
}

export const LaborCategoryMenu = (props: { categoryAndColor: CategoryAndColor }) => {
  const dispatch = useDispatch()
  const { categoryAndColor } = props
  const { user, salonId } = UseBaseApiParams()
  return (
    <Menu>
      <MenuButton>
        <MaterialIcon name="more_vert" />
      </MenuButton>
      <MenuList>
        <MenuItem
          color="black"
          onClick={() => {
            dispatch(reduceEditableLaborCategory(categoryAndColor))
          }}
        >
          Edit Menu
        </MenuItem>
        <MenuItem
          color="danger"
          onClick={() => {
            dispatch(
              dispatchDeleteLaborsForCategory({
                token: user.token,
                salonId,
                userId: user.userId,
                category: categoryAndColor.category,
              }),
            )
          }}
        >
          Delete Menu
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

export const CategoryModal = (props: {
  categoryAndColor: CategoryAndColor
  showModal: boolean
  setShowModal: (show: boolean) => void
}) => {
  const { categoryAndColor, showModal, setShowModal } = props
  const [categoryTitle, setCategoryTitle] = useState(categoryAndColor.category)
  const dispatch = useDispatch()
  const { user, salonId } = UseBaseApiParams()

  const [colorhex, setColorhex] = useState(categoryAndColor.colorhex)
  const [colorhexErrorText, setColorhexErrorText] = useState('')
  const validateSelectField = () => {
    if (!colorhex) {
      setColorhexErrorText('please select a value')
    } else {
      setColorhexErrorText('')
    }
  }

  const existingLabors = useAppSelector(selectLabors)
  const existingLaborsForCategory = existingLabors
    ? existingLabors.filter((labor) => labor.category === categoryAndColor.category)
    : null
  const formType = existingLaborsForCategory && existingLaborsForCategory.length > 0 ? 'update' : 'create'

  const onCancel = () => {
    setShowModal(false)
  }

  const onConfirm = () => {
    const models: APILaborUpsert[] = []

    if (formType === 'update') {
      existingLaborsForCategory?.forEach((labor) => {
        models.push({
          id: labor.id,
          salon_id: labor.salonId,
          user_id: labor.userId,
          name: labor.name,
          colorhex: colorhex,
          category: categoryTitle,
          duration_seconds: labor.durationSeconds,
          supply_ids: labor.supplies.map((supply) => supply.id),
        })
      })
    } else {
      models.push({
        id: null,
        salon_id: salonId,
        user_id: null,
        name: 'Sample Service (edit me)',
        colorhex: colorhex || COLORS.skylight_500,
        category: categoryTitle,
        duration_seconds: 0,
        supply_ids: [],
      })
    }
    dispatch(
      dispatchUpsertLabors({
        token: user.token,
        userId: user.userId,
        salonId: salonId,
        loadingId: LABOR_MENU_UPSERT,
        models: models,
      }),
    )
    setShowModal(false)
    dispatch(reduceSelectedLaborCategory({ category: categoryTitle, colorhex: colorhex }))
  }
  return (
    <>
      {showModal && (
        <Modal onClose={onCancel} isOpen={showModal} isCentered={true}>
          <ModalOverlay />
          <ModalContent m="12px">
            <ModalBody>
              <Flex p="36px 0 24px" direction="column">
                <Box textAlign="center" fontSize="20px" fontWeight="bold">
                  New Menu Create
                </Box>

                <Gap />

                <TextInput
                  variant="material"
                  placeholder="enter a title"
                  label="Menu Title"
                  value={categoryTitle}
                  errorText={''}
                  onChange={setCategoryTitle}
                />

                <Gap />

                <SelectInput
                  variant="material"
                  placeholder="select a color"
                  label="Service Color"
                  options={[
                    colorOption({colorhex: COLORS.midnight_500, colorName: 'midnight'}),
                    colorOption({colorhex: COLORS.lavender_500, colorName: 'lavender'}),
                    colorOption({colorhex: COLORS.peach_500, colorName: 'peachy'}),
                    colorOption({colorhex: COLORS.skylight_700, colorName: 'blue'}),
                    colorOption({colorhex: COLORS.highlight, colorName: 'green'}),
                    colorOption({colorhex: "#478CE5", colorName: 'bronze'}),
                  ]}
                  value={colorhex}
                  errorText={colorhexErrorText}
                  onBlur={validateSelectField}
                  onChange={setColorhex}
                  isClearable={false}
                />

                <Gap />

                <Flex justify="end" gridGap="12px">
                  <Button colorScheme="brand.midnight" variant="round-ghost-upper" onClick={onCancel}>
                    Cancel
                  </Button>
                  <Button
                    disabled={categoryTitle === ''}
                    colorScheme="brand.midnight"
                    variant="round"
                    onClick={() => {
                      onConfirm()
                    }}
                  >
                    {formType === 'create' ? 'Create' : 'Update'}
                  </Button>
                </Flex>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}

const colorOption = (params: {colorName: string, colorhex: string}): {label: any, value: string} => {
  const {colorhex, colorName} = params
  return {
    label: (
      <Flex align="center" gridGap="8px">
        <Circle size="18px" bg={colorhex} /> {colorName}
      </Flex>
    ),
    value: colorhex,
  }
}
