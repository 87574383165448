import { buildLaraConfig } from "../../mini-lib/lara/lara-utils";
import { GetServerBaseUrl } from "../../env";
import axios from "axios";
import { Dispatch } from "@reduxjs/toolkit";
import {getEnvConfig} from "../../config";

export const apiSyncUserSalonToPipedrive = ( params: { token: string, userId: number, salonId: number } ): Promise<any> => {
  const { token, userId, salonId } = params
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/users/${userId}/pipedrive/sync`
  const body = {}
  return axios
    .post(url, body, config)
    .then(() => {
      return
    })
    .catch(( error ) => {
      throw error
    })
}

export const dispatchSyncUserSalonToPipedrive = ( params: { token: string, userId: number, salonId: number} ) => {
  const {isProd} = getEnvConfig()

  return ( dispatch: Dispatch ) => {
    if (isProd) {
      return apiSyncUserSalonToPipedrive(params).then(() => {})
    }
  }
}
