import Smartlook from 'smartlook-client'
import { useAppSelector } from "../../hooks";
import { selectLoggedInUser } from "../../data/user/slice";
import { useEffect } from "react";
import {getEnvConfig} from "../../config";
import {hasActiveQuickTrialAndNoSubscription} from "../../data/salon/utils";

export const InitializeSmartlook = async () => {
  const user = useAppSelector(selectLoggedInUser)
  const userId = user?.userId
  const userEmail = user?.email || ''
  // const userName = user ? `${user.firstName} ${user.lastName}` : ''
  const salonId = user && user.currentSalonContext ? user.currentSalonContext.salonId : ''
  const salonName = user && user.currentSalonContext ? user.currentSalonContext.salonName : ''
  const salonType = user && user.currentSalonContext ? user.currentSalonContext.salonType : ''

  const {isProd} = getEnvConfig()

  const isTrial: boolean = user && user.currentSalonContext ? hasActiveQuickTrialAndNoSubscription(user.currentSalonContext) : false

  // note: leaving this here if/when we want it for the future
  //
  // const salonCreatedDate: Date | null = user && user.currentSalonContext ? user.currentSalonContext.createdDate : null
  // const oneMonthAgo = new Date()
  // oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1)
  // const tomorrow = new Date()
  // tomorrow.setDate(tomorrow.getDate() + 1)
  // const dateWithinLastMonth = salonCreatedDate
  //     ? isDateInRangeInclusive({ date: salonCreatedDate, start: oneMonthAgo, end: tomorrow })
  //     : false

  useEffect(
    () => {
      if (userId && isProd && isTrial) {
        // initialize smartlook if it isn't already
        //
        if (Smartlook && !Smartlook.initialized()) {
          // ref: https://app.smartlook.com/org/7o8jv8j9b46q74jj55sdjn11/project/ue3mww54oqs6n2osddbeq4bc/recordings?segment=all
          Smartlook.init('1f519c01eb4a14a2d14b2062c117217d3dc843d3')
        }
        // add salon info to smartlook
        //
        if (Smartlook?.identify) {
          Smartlook.identify(userId, {
            // name: userName,
            email: userEmail,
            salon_id: salonId,
            salon_name: salonName,
            salon_type: salonType,
          })
        }
      }
    },
    [
      userEmail,
      // userName,
      userId,
      salonId,
      salonName,
      salonType,
      isProd,
      isTrial,
    ],
  )
}